import axios from 'axios';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Redux } from '../../store/store';
import routes from '../routes';

interface IProps {
	children: JSX.Element;
}

interface ReduxProps {
	loginSuccess?: string | null;
	token?: string | null;
}

const PrivateRoute = ({ loginSuccess, children, token }: IProps & ReduxProps) => {
	const { path } = useRouteMatch();
	return loginSuccess && token ? { ...children } : <Redirect to={`/admin/${routes.login}`} />;
};

const mapStateToProps = (state: Redux) => {
	const { loginSuccess, token } = state.authentication;
	return { loginSuccess, token };
};

export default connect(mapStateToProps, null)(PrivateRoute);
