import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Images from '../../constants/Images';
import routes from '../../navigation/routes';
import { ChangeActionSaga, CHANGE_SAGA, ResetActionSaga, RESET_SAGA } from '../../store/authentication';
import { CheckActionSaga, CHECK_SAGA } from '../../store/authentication/actionTypes';
import { Redux } from '../../store/store';
import styles from './styles.module.css';

interface IProps {
	loginInProgress: boolean;
	errorChange: string;
	changeSuccess: boolean;
	change: (password: string, passwordConfirm: string, userParam: string) => void;
	check: (userParam: string) => void;
}

const ChangePassword = ({ change, check, errorChange, changeSuccess }: IProps) => {
	const [password, setPassword] = useState<string>('');
	const [passwordConfirm, setPasswordConfirm] = useState<string>('');
	const [didReset, setDidReset] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const { userParam }: { userParam: string } = useParams();


	useEffect(() => {
		check(userParam);
	}, [userParam]);

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<img src={Images.iconLogin} className={styles.headerIcon} />
				<div className={styles.headerTextsContainer}>
					<span className={styles.header}>SCHIMBARE PAROLĂ</span>
					{/* <span className={styles.subHeader}>Descriere legata de scopul acestei pagin</span> */}
				</div>
			</div>
			<div className={styles.contentContainer}>
				<div className={styles.inputsContainer}>
					{
						!didReset || !changeSuccess ?
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<div className={styles.inputContainer}>
									<img src={Images.iconParola} className={styles.smallIcon} />
									<input
										placeholder={'Parola'}
										type={'password'}
										className={styles.input}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<div className={styles.inputContainer}>
									<img src={Images.iconParola} className={styles.smallIcon} />
									<input
										placeholder={'Repetați parola'}
										type={'password'}
										className={styles.input}
										onChange={(e) => setPasswordConfirm(e.target.value)}
									/>
								</div>
							</div>
							:
							<p>Parola a fost schimbată cu succes</p>
					}
				</div>
				{
					errorChange ?
						<p>{errorChange}</p>
						: error ?
							<p>{error}</p>
							: null
				}
				{(!didReset || !changeSuccess) && <div className={styles.loginButtonContainer}>
					<div
						className={styles.loginButton}
						onClick={() => {
							if (password === passwordConfirm && password.length > 3) {
								change(password, passwordConfirm, userParam);
								setDidReset(true);
								setError('');
							}
							else if (password !== passwordConfirm) {
								setError("Parolele nu coincid.");
							}
							else {
								setError("Parola trebuie să conțină minim 4 caractere.");
							}
						}}>
						<span className={styles.loginButtonText}>SCHIMBĂ PAROLA</span>
					</div>
				</div>}
			</div>
		</div>
	);
};

const mapStateToProps = (state: Redux) => {
	const { errorChange, loginInProgress, changeSuccess } = state.authentication;
	return { errorChange, loginInProgress, changeSuccess };
};

const mapDispatchToProps = (dispatch: (arg0: ChangeActionSaga | CheckActionSaga) => void) => ({
	change: (password: string, passwordConfirm: string, userParam: string) =>
		dispatch({ type: CHANGE_SAGA, payload: { password, passwordConfirm, userParam } }),
	check: (userParam: string) =>
		dispatch({ type: CHECK_SAGA, payload: { userParam } })
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
