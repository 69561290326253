import React, { useState } from 'react';
import { StatisticiType } from '../../store/statistici/payloadTypes';
import IntervalOrar from '../IntervalOrar/IntervalOrar';
import LinieChart from '../LinieChart/LinieChart';
import Stele from '../Stele/Stele';
import styles from './styles.module.css';

interface IProps {
	title: string;
	stars?: boolean;
	multicolor?: boolean;
	data: StatisticiType[]
}

const CardStatistici = ({ title, stars, multicolor = false, data }: IProps) => {
	const [showAll, setShowAll] = useState<boolean>(false);


	return (
		<div className={styles.container}>
			<p className={styles.title}>{title}</p>
			{stars && data?.map((item, index) => (
			(!showAll && index<6 || showAll) && <Stele key={item.denumire} title={item.denumire} value={item.statistica} counter={item.counter} />
			))}
			{!stars && data?.map((item,index) => (
			(!showAll && index<6 || showAll) && <LinieChart key={item.denumire} title={item.denumire} value={item.statistica} multicolor={multicolor} />
			))}
			{
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 28 }}>
					<a onClick={(e) => {e.preventDefault();setShowAll(prev => !prev);}} href="">
						{
							showAll ?
								'Vezi doar câteva departamente'
								:
								'Vezi toate departamentele'

						}
					</a>
				</div>
			}
		</div>
	);
};

export default CardStatistici;
