import React from 'react';
import Images from '../../constants/Images';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import routes from '../routes';
import styles from './styles.module.css';

const Navbar = () => {
	return (
		<div>
			<div className={styles.container}>
				<Row>
					<Col lg={12} md={12} sm={12} xs={12}>
						<img src={Images.siglaReghin} className={styles.logoReghinNavbar} />
						<a href='' className={styles.buttonMeniuNavbar} data-bs-toggle="collapse" data-bs-target="#navbar">
							<img src={Images.iconMeniuNavbar} className={styles.iconMeniuNavbar} />
						</a>
					</Col>
				</Row>
			</div >
			<Row>
				<Col lg={12} md={12} sm={12} xs={12}>
					<div className={['collapse', 'navbar-collapse', styles.navbarStyle1].join(' ')} id="navbar">
						<ul className="navbar-nav mr-auto">
							<li className="nav-item">
								<div className={styles.navItemLink}>
									<Link to={routes.root} className={styles.linkText}>
										<img src={Images.iconMeniuSurvey} className={styles.linkImage} />
										{'Evaluarea satisfacției'}
									</Link>
								</div>
							</li>
							<li className="nav-item">
								<div className={styles.navItemLink}>
									<a href={process.env.REACT_APP_URL_PORTAL} className={styles.linkText}>
										<img src={Images.iconMeniuPortal} className={styles.linkImage} />
										{'Înapoi la portal'}	
									</a>
								</div>
							</li>
						</ul>
					</div>
				</Col >
			</Row >
		</div>
	);
};

export default Navbar;