import { put, takeLatest, call } from '@redux-saga/core/effects';
import axios from 'axios';
import { tokenReset } from '../authentication/actions';
import { TOKEN_RESET } from '../authentication/actionTypes';
import { store } from '../store';
import {
	ERROR,
	FETCH_STATISTICI_CORUPTIE_SAGA,
	FETCH_STATISTICI_INTEGRITATE_SAGA,
	FETCH_STATISTICI_SATISFACTIE_SAGA,
	FETCH_STATISTICI_CORUPTIE,
	FETCH_STATISTICI_INTEGRITATE,
	FETCH_STATISTICI_SATISFACTIE,
	LOADING_STATISTICI_CORUPTIE,
	LOADING_STATISTICI_INTEGRITATE,
	LOADING_STATISTICI_SATISFACTIE,
	FetchStatisticiSatisfactieActionSaga,
	FetchStatisticiCoruptieActionSaga,
	FetchStatisticiIntegritateActionSaga,
} from './actionTypes';
import { StatisticiType } from './payloadTypes';

const fetchCoruptie = async (an: string, token: string): Promise<{ data: StatisticiType[], shouldReset: boolean }> => {
	const resp: { data: StatisticiType[], shouldReset: boolean } = await axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/statistici_coruptie`,
		{
			an
		}, {
		headers: {
			'tkn': token
		}
	}).then((data) => data.data.detail);
	return resp;
};

const fetchIntegritate = async (an: string, token: string): Promise<{ data: StatisticiType[], shouldReset: boolean }> => {
	const resp: { data: StatisticiType[], shouldReset: boolean } = await axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/statistici_integritate`,
		{
			an,
		}, {
		headers: {
			'tkn': token
		}
	}).then((data) => data.data.detail);
	return resp;
};

const fetchSatisfactie = async (an: string, token: string): Promise<{ data: StatisticiType[], shouldReset: boolean }> => {
	const resp: { data: StatisticiType[], shouldReset: boolean } = await axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/statistici_satisfactie`,
		{
			an
		}, {
		headers: {
			'tkn': token
		}
	}).then((data) => data.data.detail);
	return resp;
};

//worker saga
function* fetchCoruptieRequest(action: FetchStatisticiCoruptieActionSaga, depth = 0) {
	try {
		yield put({ type: LOADING_STATISTICI_CORUPTIE, payload: { loading: true } });
		const token = store.getState().authentication.token;
		const { data: statisticiCoruptie, shouldReset }: { data: StatisticiType[], shouldReset: boolean } = yield call(fetchCoruptie, action.payload.an, token);
		yield put({ type: FETCH_STATISTICI_CORUPTIE, payload: { statisticiCoruptie } });
		yield put({ type: LOADING_STATISTICI_CORUPTIE, payload: { loading: false } });
		if (shouldReset) {
			const newToken: string = yield call(tokenReset, token);
			yield put({ type: TOKEN_RESET, payload: { token: newToken } });
		}
	} catch (error) {
		yield put({ type: LOADING_STATISTICI_CORUPTIE, payload: { loading: false } });
		yield put({ type: ERROR, payload: { error: true } });

	}
}

function* fetchIntegritateRequest(action: FetchStatisticiIntegritateActionSaga, depth = 0) {
	try {
		yield put({ type: LOADING_STATISTICI_INTEGRITATE, payload: { loading: true } });
		const token = store.getState().authentication.token;
		const { data: statisticiIntegritate, shouldReset }: { data: StatisticiType[], shouldReset: boolean } = yield call(fetchIntegritate, action.payload.an, token);
		yield put({ type: FETCH_STATISTICI_INTEGRITATE, payload: { statisticiIntegritate } });
		yield put({ type: LOADING_STATISTICI_INTEGRITATE, payload: { loading: false } });
		yield put({ type: ERROR, payload: { error: '' } });
		if (shouldReset) {
			const newToken: string = yield call(tokenReset, token);
			yield put({ type: TOKEN_RESET, payload: { token: newToken } });
		}
	} catch (error: any) {
		yield put({ type: LOADING_STATISTICI_INTEGRITATE, payload: { loading: false } });
		yield put({ type: ERROR, payload: { error: error.response.status ?? "A aparut o problema" } });
	}
}

function* fetchSatisfactieRequest(action: FetchStatisticiSatisfactieActionSaga, depth = 0, newToken = '') {
	try {
		yield put({ type: LOADING_STATISTICI_SATISFACTIE, payload: { loading: true } });
		const token = store.getState().authentication.token;
		const { data: statisticiSatisfactie, shouldReset }: { data: StatisticiType[], shouldReset: boolean } = yield call(fetchSatisfactie, action.payload.an, token);
		yield put({ type: FETCH_STATISTICI_SATISFACTIE, payload: { statisticiSatisfactie } });
		yield put({ type: LOADING_STATISTICI_SATISFACTIE, payload: { loading: false } });
		yield put({ type: ERROR, payload: { error: '' } });
		if (shouldReset) {
			const newToken: string = yield call(tokenReset, token);
			yield put({ type: TOKEN_RESET, payload: { token: newToken } });
		}
	} catch (error: any) {
		yield put({ type: LOADING_STATISTICI_SATISFACTIE, payload: { loading: false } });
		yield put({ type: ERROR, payload: { error: error.response.status ?? "A aparut o problema" } });
	}
}

//watcher saga
export function* watchFetchCoruptie() {
	yield takeLatest(FETCH_STATISTICI_CORUPTIE_SAGA, fetchCoruptieRequest);
}

export function* watchFetchIntegritate() {
	yield takeLatest(FETCH_STATISTICI_INTEGRITATE_SAGA, fetchIntegritateRequest);
}

export function* watchFetchSatisfactie() {
	yield takeLatest(FETCH_STATISTICI_SATISFACTIE_SAGA, fetchSatisfactieRequest);
}



