import produce from 'immer';
import { assign } from 'lodash-es';
import {
	LOGIN,
	LOGIN_IN_PROGRESS,
	ERROR,
	LOGIN_SUCCESSFUL,
	LOGOUT,
	LOGOUT_FAILURE,
	LOADING_AUTH,
	RESET_SUCCESSFUL,
	RESET_ERROR,
	CHANGE_ERROR,
	CHANGE_SUCCESSFUL,
	TOKEN_RESET
} from './actionTypes';
import { AuthTypes } from './actionTypes';
import { InitialState } from './payloadTypes';

const INITIAL_STATE: InitialState = {
	response: '',
	userId: '',
	registerError: false,
	error: '',
	loginSuccess: false,
	token: null,
	loginInProgress: false,
	logoutError: false,
	loading: false,
	resetSuccess: false,
	errorReset: '',
	errorChange: '',
	changeSuccess: false
};

// all authentication cases have the exact same code consider refactoring

export default (state = INITIAL_STATE, action: AuthTypes) => {
	switch (action.type) {
		case TOKEN_RESET:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					token: action.payload.token
				})
			);
		case LOGIN:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					userId: action.payload.userId,
					token: action.payload.token
				})
			);
		case ERROR:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					error: action.payload.error
				})
			);
		case RESET_ERROR:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					errorReset: action.payload.error
				})
			);
		case CHANGE_ERROR:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					errorChange: action.payload.error
				})
			);
		case LOGIN_IN_PROGRESS:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					loginInProgress: action.payload
				});
			});
		case LOGIN_SUCCESSFUL:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					loginSuccess: action.payload.loginSuccess,
					userId: action.payload.userId,
					token: action.payload.token
				});
			});
		case RESET_SUCCESSFUL:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					resetSuccess: action.payload.resetSuccess,
				});
			});
		case CHANGE_SUCCESSFUL:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					changeSuccess: action.payload.changeSuccess,
				});
			});
		case LOGOUT:
			return INITIAL_STATE;

		case LOGOUT_FAILURE:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					logoutError: action.payload.logoutError
				});
			});

		case LOADING_AUTH:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					loading: action.payload
				});
			});
		default:
			return state;
	}
};
