import { StatisticiType } from './payloadTypes';

export const FETCH_STATISTICI_CORUPTIE = 'FETCH_STATISTICI_CORUPTIE';
export const FETCH_STATISTICI_CORUPTIE_SAGA = 'FETCH_STATISTICI_CORUPTIE_SAGA';
export const LOADING_STATISTICI_CORUPTIE = 'LOADING_STATISTICI_CORUPTIE';

export const FETCH_STATISTICI_INTEGRITATE = 'FETCH_STATISTICI_INTEGRITATE';
export const FETCH_STATISTICI_INTEGRITATE_SAGA = 'FETCH_STATISTICI_INTEGRITATE_SAGA';
export const LOADING_STATISTICI_INTEGRITATE = 'LOADING_STATISTICI_INTEGRITATE';

export const FETCH_STATISTICI_SATISFACTIE = 'FETCH_STATISTICI_SATISFACTIE';
export const FETCH_STATISTICI_SATISFACTIE_SAGA = 'FETCH_STATISTICI_SATISFACTIE_SAGA';
export const LOADING_STATISTICI_SATISFACTIE = 'LOADING_STATISTICI_SATISFACTIE';

export const ERROR = 'ERROR';

//reducer actions Fetch
type FetchStatisticiCoruptieAction = {
	type: typeof FETCH_STATISTICI_CORUPTIE;
	payload: {
		statisticiCoruptie: StatisticiType[];
	};
};

type FetchStatisticiIntegritateAction = {
	type: typeof FETCH_STATISTICI_INTEGRITATE;
	payload: {
		statisticiIntegritate: StatisticiType[];
	};
};

type FetchStatisticiSatisfactieAction = {
	type: typeof FETCH_STATISTICI_SATISFACTIE;
	payload: {
		statisticiSatisfactie: StatisticiType[];
	};
};

//reducer actions loading
type LoadingStatisticiCoruptieAction = {
	type: typeof LOADING_STATISTICI_CORUPTIE;
	payload: {
		loadingCoruptie: boolean;
	};
};

type LoadingStatisticiIntegritateAction = {
	type: typeof LOADING_STATISTICI_INTEGRITATE;
	payload: {
		loadingIntegritate: boolean;
	};
};

type LoadingStatisticiSatisfactieAction = {
	type: typeof LOADING_STATISTICI_SATISFACTIE;
	payload: {
		loadingSatisfactie: boolean;
	};
};


//reducer fetch SAGA
export type FetchStatisticiCoruptieActionSaga = {
	type: typeof FETCH_STATISTICI_CORUPTIE_SAGA;
	payload: {
		an: string
	};
};

export type FetchStatisticiIntegritateActionSaga = {
	type: typeof FETCH_STATISTICI_INTEGRITATE_SAGA;
	payload: {
		an: string
	};
};

export type FetchStatisticiSatisfactieActionSaga = {
	type: typeof FETCH_STATISTICI_SATISFACTIE_SAGA;
	payload: {
		an: string
	};
};

type ErrorAction = {
	type: typeof ERROR;
	payload: ErrorType;
};

export type ErrorType = {
	error: boolean;
};


export type StatisticiTypes =
	| LoadingStatisticiCoruptieAction
	| LoadingStatisticiIntegritateAction
	| LoadingStatisticiSatisfactieAction
	| ErrorAction
	| FetchStatisticiCoruptieAction
	| FetchStatisticiCoruptieActionSaga
	| FetchStatisticiIntegritateAction
	| FetchStatisticiIntegritateActionSaga
	| FetchStatisticiSatisfactieAction
	| FetchStatisticiSatisfactieActionSaga
