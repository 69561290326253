import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import Images from '../../constants/Images';
import { connect } from 'react-redux';
import { Redux } from '../../store/store';
import { AcceptareComentariuActionSaga, ACCEPTARE_COMENTARIU_SAGA, FetchComentariiActionSaga, FETCH_COMENTARII_SAGA } from '../../store/comentarii/actionTypes';
import { ComentariuType } from '../../store/comentarii/payloadTypes';
import { DepartamentType } from '../../store/departamente/payloadTypes';
import { Table } from 'react-bootstrap';
import { FetchDepartamenteActionSaga, FETCH_DEPARTAMENTE_SAGA } from '../../store/departamente';
import ValoriStatistici from '../../components/ValoriStatistici/ValoriStatistici';
import Modal from '../../components/Modal/Modal';

interface IProps {
	departamente: DepartamentType[];
	fetchDepartamente: () => void;
	comentarii: ComentariuType[];
	fetchComentarii: (departamentSelectat: number) => void;
	acceptareComentariu: (comment: ComentariuType, acceptance: boolean, userId: string) => void;
	userId: string;
}

const Comentarii = ({ departamente, comentarii, fetchComentarii, acceptareComentariu, fetchDepartamente, userId }: IProps) => {

	const [departamentSelectat, setDepartamentSelectat] = useState<number>(0);
	const [comentariuSelectat, setComentariuSelectat] = useState<null | { comment: ComentariuType, acceptance: boolean, userId: string }>(null);

	useEffect(() => {
		fetchDepartamente();
		fetchComentarii(departamentSelectat);

	}, []);

	useEffect(()=>{
		fetchComentarii(departamentSelectat);
	},[departamentSelectat]);

	return (
		<div className={styles.container}>
			<div className={styles.filterContainer}>
				<div className={styles.inputContainer}>
					<img src={Images.iconMeniuDepartament} className={styles.smallIcon} />
					<select
						className={styles.input}
						onChange={(e) => setDepartamentSelectat(parseInt(e.target.value))}
						value={departamentSelectat}>
						<option value="0">Selectează departamentul dorit</option>
						{
							departamente.map(departament =>
								<option key={`departament-${departament.idDepartamentCid}`} value={departament.idDepartamentCid}>{departament.denumire}</option>)
						}
					</select>
				</div>
				{/* <div className={styles.filterButtonContainer}
					onClick={() =>}>
					<div className={styles.filterButton}>
						<span className={styles.filterButtonText}>FILTREAZĂ REZULTATE</span>
					</div>
				</div> */}
			</div>
			<div className={styles.headerContainer}>
				<img src={Images.iconComentarii} className={styles.headerIcon} />
				<div className={styles.headerTextsContainer}>
					<span className={styles.header}>Comentarii în așteptare</span>
					{/* <span className={styles.subHeader}>Descriere legata de scopul acestei pagin</span> */}
				</div>
			</div>
			<div className={styles.contentContainer}>
				<Table>
					<thead>
						<tr>
							<th style={{ width: '20%' }}>Data</th>
							<th style={{ width: '20%' }}>Evaluare</th>
							<th style={{ width: '30%' }}>Comentariu</th>
							<th style={{ width: '30%' }}>Acțiuni</th>
						</tr>
					</thead>
					<tbody>
						{
							comentarii.map((comment, index) =>
								<tr key={`comment-${comment.answerSetId}-${index}`}>
									<td>{new Date(comment.data).toLocaleString('ro-RO')}</td>
									<td>
										<ValoriStatistici score={parseFloat(comment.evaluare)} />
									</td>
									<td>{comment.comentariu}</td>
									<td>
										<div style={{ display: 'flex' }}>
											<div
												onClick={() => setComentariuSelectat({ comment, acceptance: true, userId })}
												className={styles.acceptButton}>
												Acceptă
											</div>
											<div
												onClick={() => setComentariuSelectat({ comment, acceptance: false, userId })}
												className={styles.removeButton}>
												Respinge
											</div>
										</div>
									</td>
								</tr>)
						}
					</tbody>
				</Table>

				<Modal visible={comentariuSelectat !== null} hide={() => setComentariuSelectat(null)}>
					<div>
						<h2>Sigur doriți să {comentariuSelectat?.acceptance ? 'acceptați' : 'ștergeți'} acest comentariu?</h2>
						{
							comentariuSelectat?.acceptance ?
								<p>Acceptarea comentariului duce la afișarea acestuia</p>
								:
								<p>Refuzi un comentariu, acțiunea nu poate fi modificată.</p>
						}
						<div style={{ display: 'flex' }}>
							<div
								onClick={() => {
									if (comentariuSelectat !== null) {
										acceptareComentariu(comentariuSelectat?.comment, comentariuSelectat?.acceptance, comentariuSelectat.userId);
									}
								}}
								className={styles.acceptButton}>
								Da
							</div>
							<div
								onClick={() => setComentariuSelectat(null)}
								className={styles.removeButton}>
								Nu
							</div>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	);
};


const mapStateToProps = (state: Redux) => {
	const { departamente, loading: loadingDepartamente } = state.departamente;
	const { comentarii, loading: loadingComentarii } = state.comentarii;
	const { userId, loading: loadingAuth } = state.authentication;
	return { departamente, comentarii, userId, loading: loadingDepartamente || loadingComentarii || loadingAuth };
};

const mapDispatchToProps = (dispatch: (arg0: AcceptareComentariuActionSaga | FetchComentariiActionSaga | FetchDepartamenteActionSaga) => void) => ({
	fetchComentarii: (departamentSelectat: number) =>
		dispatch({ type: FETCH_COMENTARII_SAGA, payload: { departamentSelectat } }),
	acceptareComentariu: (comment: ComentariuType, acceptance: boolean, userId: string) =>
		dispatch({ type: ACCEPTARE_COMENTARIU_SAGA, payload: { comment, acceptance, userId } }),
	fetchDepartamente: () =>
		dispatch({ type: FETCH_DEPARTAMENTE_SAGA, payload: null })
});

export default connect(mapStateToProps, mapDispatchToProps)(Comentarii);