import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styles from './styles.module.css';

const Footer = () => {
	return (
		<div className={styles.container}>
			<Row>
				<Col lg={12} md={12} sm={12} xs={12}>
					<p className={styles.footerText}>
					Această pagină web este cofinanțată din Fondul Social European prin Programul Operațional Capacitate Administrativă 2014-2020 <a href="https://www.poca.ro">www.poca.ro</a> <br/> 
					MySMIS 152005 / SIPOCA 1125 <br/>
					Pentru informații detaliate despre celelalte programe cofinanțate de Uniunea Europeană, vă invităm să vizitați <a href="https://www.fonduri-ue.ro">www.fonduri-ue.ro</a><br/>
					Conținutul acestei pagini web nu reprezintă în mod obligatoriu poziția oficială a Uniunii Europene. Întreaga responsabilitate asupra corectitudinii și coerenței informațiilor prezentate revine inițiatorilor paginii web.
					</p>
				</Col>
			</Row>
		</div>
	);
};

export default Footer;