import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import QRCode from 'react-qr-code';
import Images from '../../constants/Images';
import { AtribuireDepartamentActionSaga, FetchDepartamenteActionSaga, ATRIBUIRE_DEPARTAMENT_SAGA, FETCH_DEPARTAMENTE_SAGA } from '../../store/departamente';
import { DepartamentType } from '../../store/departamente/payloadTypes';
import { Redux } from '../../store/store';
import styles from './styles.module.css';
import { PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';
import PdfQR from '../../components/PdfQR/PdfQR';

const pdfStyles = StyleSheet.create({
	download: {
		width: '50%',
		textAlign: 'center',
		marginTop: '20px',
		paddingVertical: '10px',
		paddingHorizontal: '15px',
		backgroundColor: '#006AB3',
		border: '1px solid #003F88',
		color: 'white'
	}
});

interface IProps {
	departamente: DepartamentType[];
	fetchDepartamente: () => void;
	atribuireDepartamente: (idDepartament: string, urlChestionar: string) => void;
}

const AtribuireDepartament = ({ departamente, fetchDepartamente, atribuireDepartamente }: IProps) => {
	const [departamentDraft, setDepartamentDraft] = useState<string>('');
	const [urlDraft, setUrlDraft] = useState<string>('');
	const [selectedUrl, setSelectedUrl] = useState<string>('');
	const [QRUrl, setQRUrl] = useState<string>('');
	const [selectedDepartament, setSelectedDepartament] = useState<string>('');
	const [canDownload, setCanDownload] = useState(false);

	const generateQRUrl = () => {
		const svg = document.getElementById('QRCode');
		if (svg) {
			const svgData = new XMLSerializer().serializeToString(svg);
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			const img = new Image();
			img.onload = () => {
				canvas.width = img.width;
				canvas.height = img.height;
				ctx?.drawImage(img, 0, 0);
				const pngFile = canvas.toDataURL('image/jpeg');
				setQRUrl(pngFile);
				setCanDownload(true);
			};
			img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
		}
	};

	useEffect(() => {
		fetchDepartamente();
	}, []);

	useEffect(() => {
		if (!departamentDraft.length) {
			if (departamente.length) {
				setUrlDraft(departamente[0].urlChestionar);
			}
		}
		else {
			const url = departamente.find((departament) => departament.idDepartamentCid === departamentDraft)?.urlChestionar;
			setUrlDraft(url ?? '');
		}
	}, [departamente, departamentDraft]);

	useEffect(() => {
		if (selectedUrl)
			generateQRUrl();
	}, [selectedUrl]);

	useEffect(()=>{
		if(!departamentDraft && departamente.length){
			setDepartamentDraft(departamente[0].idDepartamentCid);
		}
	},[departamente]);

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<img src={Images.iconAtribuireDepartament} className={styles.headerIcon} />
				<div className={styles.headerTextsContainer}>
					<span className={styles.header}>Atribuirea chestionarului unui departament</span>
					{/* <span className={styles.subHeader}>Descriere legata de scopul acestei pagin</span> */}
				</div>
			</div>
			<div className={styles.contentContainer}>
				<div className={styles.inputsContainer}>
					<div className={styles.inputContainer}>
						<img src={Images.iconSelectieDepartament} className={styles.smallIcon} />
						<select
							placeholder={'Selecteaza departamentul dorit'}
							className={styles.input}
							onChange={(e) => setDepartamentDraft(e.target.value)}
						>
							{
								departamente.map((departament: DepartamentType) =>
									<option key={`departament-${departament.idDepartamentCid}`} value={departament.idDepartamentCid}>{departament.denumire}</option>)
							}
						</select>
					</div>
				</div>
				<div className={styles.inputsContainer}>
					<div className={styles.inputContainer}>
						<img src={Images.iconLink} className={styles.smallIcon} />
						<input
							placeholder={'Adaugă URL-ul chestionarului'}
							type={'text'}
							value={urlDraft}
							className={styles.input}
							onChange={(e) => setUrlDraft(e.target.value)}
						/>
					</div>
				</div>
				<div className={styles.loginButtonContainer} onClick={() => {
					atribuireDepartamente(departamentDraft, urlDraft);
				}}>
					<div className={styles.loginButton}>
						<span className={styles.loginButtonText}>ATRIBUIE DEPARTAMENTUL</span>
					</div>
				</div>

				<Table style={{ marginTop: 100 }}>
					<thead>
						<th>Departament</th>
						<th>Url</th>
						<th>Acțiuni</th>
					</thead>
					<tbody>
						{departamente.map((dep: DepartamentType) => (
							<tr key={`departamente-table-${dep.idDepartamentCid}`}>
								<td>{dep.denumire}</td>
								<td>{dep.urlChestionar}</td>
								<td>
									<div
										onClick={() => { setSelectedUrl(dep.urlChestionar); setSelectedDepartament(dep.denumire); }}
										style={{ textAlign: 'center', padding: 20, paddingTop: 5, paddingBottom: 5, backgroundColor: '#006AB3', borderColor: '#003F88', borderWidth: 1, color: 'white', cursor: 'pointer', marginRight: 20 }}>
										GENERARE QR
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>

			<Modal visible={selectedUrl.length != 0} hide={() => {setSelectedUrl('');setCanDownload(false);}}>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<QRCode id="QRCode" value={selectedUrl} width={500} height={500} size={250} />
					{
						canDownload &&
						<PDFDownloadLink
							style={pdfStyles.download}
							onClick={(e: any) => {
								e.preventDefault();
								const downloadLink = document.createElement('a');
								downloadLink.download = 'QRCode';
								downloadLink.href = `${e.target.href}`;
								downloadLink.click();
								setCanDownload(false);
							}}
							document={<PdfQR url={selectedUrl} departament={selectedDepartament} qrUrl={QRUrl} />}
							fileName={'qr.pdf'}
						>
							Descarcă
						</PDFDownloadLink>
					}
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: Redux) => {
	const { departamente } = state.departamente;
	return { departamente };
};

const mapDispatchToProps = (dispatch: (arg0: FetchDepartamenteActionSaga | AtribuireDepartamentActionSaga) => void) => ({
	fetchDepartamente: () =>
		dispatch({ type: FETCH_DEPARTAMENTE_SAGA, payload: null }),
	atribuireDepartamente: (idDepartament: string, urlChestionar: string) =>
		dispatch({ type: ATRIBUIRE_DEPARTAMENT_SAGA, payload: { idDepartament, urlChestionar } })

});

export default connect(mapStateToProps, mapDispatchToProps)(AtribuireDepartament);
