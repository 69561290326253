import axios from 'axios';
import React, { useState } from 'react';
import styles from './styles.module.css';

const CreareChestionar = () => {
	const [iframeKey, setIframeKey] = useState<number>(0);

	return (
		<div className={styles.container}>

			<iframe
				key={iframeKey}
				sandbox="allow-scripts allow-forms allow-same-origin allow-storage-access-by-user-activation"
				src={process.env.REACT_APP_URL_EUSURVEY}
				className={styles.eusurveyFrame}
			/>
		</div>
	);
};

export default CreareChestionar;
