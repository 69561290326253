import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import mainRoutes from './mainRoutes';
import AdminNavigation from '../features/admin/navigation/Navigation/Navigation';
import ClientNavigation from '../features/client/navigation/Navigation/Navigation';

const MainNavigation = () => {
	return (
		<div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
			<Router>
				<Switch>
					<Route
						path={mainRoutes.admin}
					>
						<AdminNavigation />
					</Route>
					<Route
						path={mainRoutes.root}
					>
						<ClientNavigation />
					</Route>
				</Switch>
			</Router>
		</div>
	);
};

export default MainNavigation;
