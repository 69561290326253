import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import CardStatistici from '../../components/CardStatistici/CardStatistici';
import Images from '../../constants/Images';
import { FetchStatisticiCoruptieActionSaga, FetchStatisticiIntegritateActionSaga, FetchStatisticiSatisfactieActionSaga, FETCH_STATISTICI_CORUPTIE_SAGA, FETCH_STATISTICI_INTEGRITATE_SAGA, FETCH_STATISTICI_SATISFACTIE_SAGA } from '../../store/statistici/actionTypes';
import { StatisticiType } from '../../store/statistici/payloadTypes';
import { Redux } from '../../store/store';
import styles from './styles.module.css';

interface ReduxProps {
	statisticiCoruptie: StatisticiType[],
	statisticiSatisfactie: StatisticiType[],
	statisticiIntegritate: StatisticiType[],
	fetchStatisticiSatisfactie: (an: string) => void,
	fetchStatisticiCoruptie: (an: string) => void,
	fetchStatisticiIntegritate: (an: string) => void,
}

const Statistici = ({
	statisticiCoruptie,
	statisticiIntegritate,
	statisticiSatisfactie,
	fetchStatisticiSatisfactie,
	fetchStatisticiCoruptie,
	fetchStatisticiIntegritate
}: ReduxProps) => {
	const [selectedAn, setSelectedAn] = useState<string>(new Date().getFullYear().toString());
	const [currentYear, setCurrentYear] = useState<string>('');

	useEffect(() => {
		fetchStatisticiCoruptie(selectedAn);
		fetchStatisticiIntegritate(selectedAn);
		fetchStatisticiSatisfactie(selectedAn);
	}, [selectedAn]);

	useEffect(() => {
		const currentYear = new Date().getFullYear();
		setCurrentYear(currentYear.toString());
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.filterContainer}>
				<div className={styles.inputContainer}>
					<img src={Images.iconMeniuDepartament} className={styles.smallIcon} />
					<select
						className={styles.input}
						onChange={(e) => setSelectedAn(e.target.value)}
						value={selectedAn}>
						<>
							<option key={`year-all`} value={'0'}>{'Pentru toți anii'}</option>
							<option key={`year-${currentYear}`} value={currentYear}>{'Pentru anul în curs'}</option>
						</>
					</select>
				</div>
			</div>
			<div className={styles.headerContainer}>
				<img src={Images.iconStatistici} className={styles.headerIcon} />
				<div className={styles.headerTextsContainer}>
					<span className={styles.header}>Statistici</span>
					{/* <span className={styles.subHeader}>Descriere legata de scopul acestei pagin</span> */}
				</div>
			</div>
			<div className={styles.contentContainer}>
				<Row>
					<Col lg={6}>
						<CardStatistici title={'Riscurile și vulnerabilitățile de corupție'} multicolor data={statisticiCoruptie} />

					</Col>
					<Col lg={6}>
						<CardStatistici title={'Incidente de integritate'} multicolor data={statisticiIntegritate} />
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<CardStatistici title={'Gradul de satisfacție al cetățenilor pe departamente'} stars data={statisticiSatisfactie} />
					</Col>
				</Row>
			</div>
		</div>
	);
};


const mapStateToProps = (state: Redux) => {
	const { statisticiCoruptie, statisticiIntegritate, statisticiSatisfactie } = state.statistici;
	return { statisticiCoruptie, statisticiIntegritate, statisticiSatisfactie };
};

const mapDispatchToProps = (dispatch: (arg0: FetchStatisticiSatisfactieActionSaga | FetchStatisticiCoruptieActionSaga | FetchStatisticiIntegritateActionSaga) => void) => ({
	fetchStatisticiSatisfactie: (an: string) =>
		dispatch({ type: FETCH_STATISTICI_SATISFACTIE_SAGA, payload: { an } }),
	fetchStatisticiIntegritate: (an: string) =>
		dispatch({ type: FETCH_STATISTICI_INTEGRITATE_SAGA, payload: { an } }),
	fetchStatisticiCoruptie: (an: string) =>
		dispatch({ type: FETCH_STATISTICI_CORUPTIE_SAGA, payload: { an } }),

});

export default connect(mapStateToProps, mapDispatchToProps)(Statistici);
