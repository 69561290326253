import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Images from '../../constants/Images';
import routes from '../../navigation/routes';
import { ResetActionSaga, RESET_SAGA } from '../../store/authentication';
import { Redux } from '../../store/store';
import styles from './styles.module.css';

interface IProps {
	loginInProgress: boolean;
	errorReset: string;
	resetSuccess: boolean;
	reset: (email: string) => void;
}

const ForgotPassword = ({ reset, errorReset, resetSuccess }: IProps) => {
	const [user, setUser] = useState<string>('');
	const [didReset, setDidReset] = useState<boolean>(false);
	const navigate = useHistory();

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<img src={Images.iconLogin} className={styles.headerIcon} />
				<div className={styles.headerTextsContainer}>
					<span className={styles.header}>RESETARE PAROLĂ</span>
					{/* <span className={styles.subHeader}>Descriere legata de scopul acestei pagin</span> */}
				</div>
			</div>
			<div className={styles.contentContainer}>
				<div className={styles.inputsContainer}>
					{
						!didReset || !resetSuccess ?
							<div className={styles.inputContainer}>
								<img src={Images.iconEmail} className={styles.smallIcon} />
								<input
									placeholder={'Utilizator'}
									type={'text'}
									className={styles.input}
									onChange={(e) => setUser(e.target.value)}
								/>
							</div>
							:
							<p>Parola a fost resetată și trimisă pe email</p>
					}
				</div>
				{
					errorReset&&
					<p>{errorReset}</p>
				}
				{(!didReset || !resetSuccess) && <div className={styles.loginButtonContainer}>
					<div
						className={styles.loginButton}
						onClick={() => {
							reset(user);
							setDidReset(true);
						}}>
						<span className={styles.loginButtonText}>RESETEAZĂ PAROLA</span>
					</div>
				</div>}
			</div>
		</div>
	);
};

const mapStateToProps = (state: Redux) => {
	const { errorReset, loginInProgress, resetSuccess } = state.authentication;
	return { errorReset, loginInProgress, resetSuccess };
};

const mapDispatchToProps = (dispatch: (arg0: ResetActionSaga) => void) => ({
	reset: (user: string) =>
		dispatch({ type: RESET_SAGA, payload: { user } })
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
