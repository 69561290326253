export const LOGIN = 'LOGIN';
export const LOGIN_SAGA = 'LOGIN_SAGA';
export const ERROR = 'ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SAGA = 'LOGOUT_SAGA';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';

//reducer actions
type LoginAction = {
	type: typeof LOGIN;
	payload: {
		userId: string;
		token: string | null;
	};
};

type ErrorAction = {
	type: typeof ERROR;
	payload: ErrorType;
};

type LogOutAction = {
	type: typeof LOGOUT;
	payload: null;
};

type LogOutFailureAction = {
	type: typeof LOGOUT_FAILURE;
	payload: LogoutFailureType;
};

type LoginSuccessAction = {
	type: typeof LOGIN_SUCCESSFUL;
	payload: LoginSuccessType;
};

//saga actions
export type LoginActionSaga = {
	type: typeof LOGIN_SAGA;
	payload: LoginType;
};

export type LogOutActionSaga = {
	type: typeof LOGOUT_SAGA;
	payload: null;
};

export type LoginInProgressAction = {
	type: typeof LOGIN_IN_PROGRESS;
	payload: boolean;
};

export type ErrorType = {
	error: boolean;
};

export type LoginSuccessType = {
	loginSuccess: boolean;
	token: string;
};

export type LoginType = {
	email: string;
	password: string;
};

export type LogoutFailureType = {
	logoutError: boolean;
};

export type AuthTypes =
	| LoginAction
	| LoginInProgressAction
	| ErrorAction
	| LoginSuccessAction
	| LogOutAction
	| LogOutFailureAction;
