import { put, takeLatest, call } from '@redux-saga/core/effects';
import axios, { AxiosError } from 'axios';
import { FETCH_COMENTARII, FETCH_COMENTARII_SAGA, ACCEPTARE_COMENTARIU, ACCEPTARE_COMENTARIU_SAGA } from '.';
import { tokenReset } from '../authentication/actions';
import { TOKEN_RESET } from '../authentication/actionTypes';
import { store } from '../store';

import {
	ERROR,
	AcceptareComentariuActionSaga,
	FetchComentariiActionSaga,
	LOADING_COMENTARII
} from './actionTypes';
import { ComentariuType } from './payloadTypes';

const fetch = async (departamentSelectat: number, token: string, depth = 0): Promise<{ data: ComentariuType[], shouldReset: boolean }> => {
	const resp: { data: ComentariuType[], shouldReset: boolean } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/comentarii/${departamentSelectat}`, {
		headers: {
			'tkn': token
		}
	}).then((data) => data.data.detail);
	return resp;
};

const acceptare = async (payload: { comment: ComentariuType, acceptance: boolean, userId: string }, token: string): Promise<{ success: boolean, shouldReset: boolean }> => {
	const resp: { success: boolean, shouldReset: boolean } = await axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/comentarii`, {
		...payload
	}, {
		headers: {
			'tkn': token
		}
	}).then((data) => data.data.detail);
	return resp;
};

//worker saga
function* fetchRequest(action: FetchComentariiActionSaga, depth = 0) {
	try {
		yield put({ type: LOADING_COMENTARII, payload: true });
		const token = store.getState().authentication.token;
		const { data: comentarii, shouldReset }: { data: ComentariuType[], shouldReset: boolean } = yield call(fetch, action.payload.departamentSelectat, token);
		yield put({ type: FETCH_COMENTARII, payload: { comentarii } });
		yield put({ type: LOADING_COMENTARII, payload: false });
		yield put({ type: ERROR, payload: { error: '' } });
		if (shouldReset) {
			const newToken: string = yield call(tokenReset, token);
			yield put({ type: TOKEN_RESET, payload: { token: newToken } });
		}
	} catch (error: any) {
		yield put({ type: ERROR, payload: { error: error.response.status ?? "A aparut o problema." } });
		yield put({ type: LOADING_COMENTARII, payload: false });
	}
}

function* acceptRequest(action: AcceptareComentariuActionSaga, depth = 0) {
	try {
		yield put({ type: LOADING_COMENTARII, payload: true });
		const token = store.getState().authentication.token;
		const { success, shouldReset }: { success: boolean, shouldReset: boolean } = yield call(acceptare, action.payload, token);
		if (success)
			yield put({ type: ACCEPTARE_COMENTARIU, payload: action.payload });
		yield put({ type: LOADING_COMENTARII, payload: false });
		yield put({ type: ERROR, payload: { error: '' } });
		if (shouldReset) {
			const token = store.getState().authentication.token;
			const newToken: string = yield call(tokenReset, token);
			yield put({ type: TOKEN_RESET, payload: { token: newToken } });
		}
	} catch (error: any) {
		yield put({ type: ERROR, payload: { error: error.response.status ?? "A aparut o problema" } });
		yield put({ type: LOADING_COMENTARII, payload: false });

	}
}

//watcher saga
export function* watchFetch() {
	yield takeLatest(FETCH_COMENTARII_SAGA, fetchRequest);
}

export function* watchAccept() {
	yield takeLatest(ACCEPTARE_COMENTARIU_SAGA, acceptRequest);
}
