import { put, takeLatest, call } from '@redux-saga/core/effects';
import axios from 'axios';
import { FETCH_DEPARTAMENTE, FETCH_DEPARTAMENTE_SAGA, ATRIBUIRE_DEPARTAMENT, ATRIBUIRE_DEPARTAMENT_SAGA } from '.';
import { tokenReset } from '../authentication/actions';
import { TOKEN_RESET } from '../authentication/actionTypes';
import { store } from '../store';
import {
	ERROR,
	AtribuireDepartamentActionSaga,
	AtribuireDepartamentType,
	LOADING_DEPARTAMENTE
} from './actionTypes';
import { DepartamentType } from './payloadTypes';

const fetch = async (token: string): Promise<{ data: DepartamentType[], shouldReset: boolean }> => {
	const resp: { data: DepartamentType[], shouldReset: boolean } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/departamente`, {
		headers: {
			'tkn': token,
		}
	}).then((data) => data.data.detail);
	return resp;
};

const atribuire = async (payload: AtribuireDepartamentType, token: string): Promise<{ success: boolean, shouldReset: boolean }> => {
	const resp: { success: boolean, shouldReset: boolean } = await axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/departamente`, {
		...payload
	}, {
		headers: {
			'tkn': token
		}
	}).then((data) => data.data.detail);
	return resp;
};

//worker saga
function* fetchRequest(depth = 0) {
	try {
		const token = store.getState().authentication.token;
		yield put({ type: LOADING_DEPARTAMENTE, payload: { loading: true } });
		const { data: departamente, shouldReset }: { data: DepartamentType[], shouldReset: boolean } = yield call(fetch, token);
		yield put({ type: FETCH_DEPARTAMENTE, payload: { departamente } });
		yield put({ type: LOADING_DEPARTAMENTE, payload: { loading: false } });
		yield put({ type: ERROR, payload: { error: '' } });
		if (shouldReset) {
			const newToken: string = yield call(tokenReset, token);
			yield put({ type: TOKEN_RESET, payload: { token: newToken } });
		}
	} catch (error: any) {
		yield put({ type: LOADING_DEPARTAMENTE, payload: { loading: false } });
		yield put({ type: ERROR, payload: { error: error.response.status ?? "A aparut o problema" } });
	}
}

function* atribuireRequest(action: AtribuireDepartamentActionSaga, depth = 0) {
	try {
		const token = store.getState().authentication.token;
		yield put({ type: LOADING_DEPARTAMENTE, payload: { loading: true } });
		const { success, shouldReset }: { shouldReset: boolean, success: boolean } = yield call(atribuire, action.payload, token);
		if (success)
			yield put({ type: ATRIBUIRE_DEPARTAMENT, payload: action.payload });

		yield put({ type: LOADING_DEPARTAMENTE, payload: { loading: false } });
		yield put({ type: ERROR, payload: { error: '' } });
		if (shouldReset) {
			const newToken: string = yield call(tokenReset, token);
			yield put({ type: TOKEN_RESET, payload: { token: newToken } });
		}
	} catch (error: any) {
		yield put({ type: ERROR, payload: { error: error.response.status ?? "A aparut o problema" } });
		yield put({ type: LOADING_DEPARTAMENTE, payload: { loading: false } });
	}
}

//watcher saga
export function* watchFetch() {
	yield takeLatest(FETCH_DEPARTAMENTE_SAGA, fetchRequest);
}

export function* watchAtribuire() {
	yield takeLatest(ATRIBUIRE_DEPARTAMENT_SAGA, atribuireRequest);
}
