import React from 'react';
import { ColorRing } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Redux } from '../../store/store';
import styles from './styles.module.css';

interface IProps {
	loading: boolean
}

const Loader = ({ loading }: IProps) => {
	return loading ? <div style={{ backgroundColor: '#cecece', opacity: 0.5, width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0 }}>
		<div className={styles.loader}></div>
	</div> : null;
};

const mapStateToProps = (state: Redux) => {
	const { loading: loadingDepartamente } = state.departamente;
	const { loading: loadingComentarii } = state.comentarii;
	const { loading: loadingAuth } = state.authentication;
	return { loading: loadingDepartamente || loadingComentarii || loadingAuth };
};

export default connect(mapStateToProps)(Loader);
