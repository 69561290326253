import produce from 'immer';
import { assign } from 'lodash-es';
import { ATRIBUIRE_DEPARTAMENT, DepartamenteTypes, FETCH_DEPARTAMENTE, LOADING_DEPARTAMENTE } from './actionTypes';
import { InitialState } from './payloadTypes';

const INITIAL_STATE: InitialState = {
	departamente: [],
	error: '',
	loading: false
};

// all authentication cases have the exact same code consider refactoring

export default (state = INITIAL_STATE, action: DepartamenteTypes) => {
	switch (action.type) {
		case ATRIBUIRE_DEPARTAMENT:
		{
			const index = state.departamente.findIndex((departament) => departament.idDepartamentCid === action.payload.idDepartament);
			const tempDep = [...state.departamente];
			tempDep[index] = { ...tempDep[index], urlChestionar: action.payload.urlChestionar };
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					departamente: tempDep,
				});
			}
			);
		}
		case FETCH_DEPARTAMENTE:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					departamente: action.payload.departamente
				})
			);
		case LOADING_DEPARTAMENTE:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					loading: action.payload.loading
				})
			);
		default:
			return state;
	}
};
