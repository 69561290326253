import React from 'react';
import Images from '../../constants/Images';

const NotFound = () => {
	return (
		<div>
			<div style={{ width: 300, display: 'flex', flexDirection: 'row' }}>
				<div style={{ margin: 5, display: 'flex', height: 20, flex: 1 }}>Scazut</div>
				<div style={{ margin: 5, display: 'flex', height: 20, flex: 1 }}></div>
				<div style={{ margin: 5, display: 'flex', height: 20, flex: 1 }}>mediu</div>
				<div style={{ margin: 5, display: 'flex', height: 20, flex: 1 }}></div>
				<div style={{ margin: 5, display: 'flex', height: 20, flex: 1 }}>crescut</div>
			</div>
			<div style={{ width: 300, display: 'flex', flexDirection: 'row' }}>
				<div style={{ margin: 5, display: 'flex', height: 20, backgroundColor: '#dc1f1c', flex: 1 }}>
					<img src={Images.UpArrow}></img>
				</div>
				<div style={{ margin: 5, display: 'flex', height: 20, backgroundColor: '#f97001', flex: 1 }}></div>
				<div style={{ margin: 5, display: 'flex', height: 20, backgroundColor: '#f8ba04', flex: 1 }}></div>
				<div style={{ margin: 5, display: 'flex', height: 20, backgroundColor: '#c4d600', flex: 1 }}></div>
				<div style={{ margin: 5, display: 'flex', height: 20, backgroundColor: '#75ba24', flex: 1 }}></div>
			</div>
		</div>
	);
};

export default NotFound;
