import React, { useEffect, useState } from 'react';
import Images from '../../constants/Images';
import styles from './styles.module.css';

interface IProps {
	title: string;
	value: number;
	counter: number;
}

const Stele = ({ title, value, counter }: IProps) => {
	const [statisticaSatisfactieDepartament, setStatisticaSatisfactieDepartament] = useState<number>(value);
	const [imageSatisfactie, setImagineSatisfactie] = useState<string>('');

	useEffect(() => {
		if (statisticaSatisfactieDepartament && statisticaSatisfactieDepartament < 0.2) {
			setImagineSatisfactie(Images.imgSatisfactieScazut);
		}
		else if (statisticaSatisfactieDepartament > 0.2 && statisticaSatisfactieDepartament <= 0.4) {
			setImagineSatisfactie(Images.imgSatisfactieMediu1);
		}
		else if (statisticaSatisfactieDepartament > 0.4 && statisticaSatisfactieDepartament <= 0.6) {
			setImagineSatisfactie(Images.imgSatisfactieMediu2);
		}
		else if (statisticaSatisfactieDepartament > 0.6 && statisticaSatisfactieDepartament <= 0.8) {
			setImagineSatisfactie(Images.imgSatisfactieMediu3);
		}
		else if (statisticaSatisfactieDepartament > 0.8 && statisticaSatisfactieDepartament <= 1) {
			setImagineSatisfactie(Images.imgSatisfactieCrescut);
		}
		else if (!statisticaSatisfactieDepartament) {
			setImagineSatisfactie(Images.imgSatisfactieDefault);
		}
	}, [statisticaSatisfactieDepartament]);
	return (
		<div className={styles.container}>
			<div className={styles.row}>
				<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
					<p className={styles.title}>{title}</p>
					<div style={{ display: 'flex', flex: 1 }}>
						<img src={imageSatisfactie} />
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-end' }}>
					<p>{counter}</p>
					<p>Completate</p>
				</div>
			</div>
			<p></p>
		</div>
	);
};

export default Stele;
