import { DepartamentType } from './payloadTypes';

export const FETCH_DEPARTAMENTE = 'FETCH_DEPARTAMENTE';
export const FETCH_DEPARTAMENTE_SAGA = 'FETCH_DEPARTAMENTE_SAGA';
export const ERROR = 'ERROR';
export const ATRIBUIRE_DEPARTAMENT = 'ATRIBUIRE_DEPARTAMENT';
export const ATRIBUIRE_DEPARTAMENT_FAILURE = 'ATRIBUIRE_DEPARTAMENT_FAILURE';
export const ATRIBUIRE_DEPARTAMENT_SAGA = 'ATRIBUIRE_DEPARTAMENT_SAGA';
export const FETCH_DEPARTAMENTE_SUCCESSFUL = 'FETCH_DEPARTAMENTE_SUCCESSFUL';
export const FETCH_DEPARTAMENTE_IN_PROGRESS = 'FETCH_DEPARTAMENTE_IN_PROGRESS';
export const LOADING_DEPARTAMENTE = 'LOADING_DEPARTAMENTE';

//reducer actions
type FetchDepartamenteAction = {
	type: typeof FETCH_DEPARTAMENTE;
	payload: {
		departamente: DepartamentType[]
	};
};

type ErrorAction = {
	type: typeof ERROR;
	payload: ErrorType;
};

type AtribuireDepartamentAction = {
	type: typeof ATRIBUIRE_DEPARTAMENT;
	payload: AtribuireDepartamentType;
};


//saga actions
export type FetchDepartamenteActionSaga = {
	type: typeof FETCH_DEPARTAMENTE_SAGA;
	payload: null;
};

export type AtribuireDepartamentActionSaga = {
	type: typeof ATRIBUIRE_DEPARTAMENT_SAGA;
	payload: AtribuireDepartamentType;
};

export type LoadingDepartamenteAction = {
	type: typeof LOADING_DEPARTAMENTE;
	payload: {
		loading: boolean;
	};
};

export type ErrorType = {
	error: boolean;
};

export type AtribuireDepartamentType = {
	idDepartament: string;
	urlChestionar: string;
};

export type DepartamenteTypes =
	| LoadingDepartamenteAction
	| FetchDepartamenteAction
	| FetchDepartamenteActionSaga
	| ErrorAction
	| AtribuireDepartamentAction
	| AtribuireDepartamentActionSaga
