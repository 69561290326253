import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../../client/navigation/Footer/Footer';
import Images from '../../constants/Images';
import routes from '../../navigation/routes';
import { LoginActionSaga, LOGIN_SAGA } from '../../store/authentication';
import { Redux } from '../../store/store';
import styles from './styles.module.css';

interface IProps {
	error: string;
	loginInProgress: boolean;
	loginSuccess: boolean;
	login: (email: string, pass: string) => void;
}

const LoginScreen = ({ error, loginInProgress, loginSuccess, login }: IProps) => {
	const [emailDraft, setEmailDraft] = useState<string>('');
	const [passwordDraft, setPasswordDraft] = useState<string>('');
	const [seePass, setSeePass] = useState<boolean>(false);

	const navigate = useHistory();

	useEffect(() => {
		if (loginSuccess) {
			navigate.replace(routes.creareChestionar);
		}
	}, [loginSuccess]);

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<img src={Images.iconLogin} className={styles.headerIcon} />
				<div className={styles.headerTextsContainer}>
					<span className={styles.header}>LOGARE</span>
					{/* <span className={styles.subHeader}>Descriere legata de scopul acestei pagin</span> */}
				</div>
			</div>
			<div className={styles.contentContainer}>
				<div className={styles.inputsContainer}>
					<div className={styles.inputContainer}>
						<img src={Images.iconEmail} className={styles.smallIcon} />
						<input
							placeholder={'Utilizator'}
							type={'text'}
							className={styles.input}
							onChange={(e) => setEmailDraft(e.target.value)}
						/>
					</div>
					<div className={styles.inputContainer}>
						<img src={Images.iconParola} className={styles.smallIcon} />
						<input
							placeholder={'Parolă'}
							type={seePass ? 'text' : 'password'}
							className={styles.input}
							onChange={(e) => setPasswordDraft(e.target.value)}
							onKeyDown={(e) => e.key === 'Enter' ? login(emailDraft, passwordDraft) : null}
						/>
						<div onClick={() => setSeePass((prevState) => !prevState)}>
							<img
								src={seePass ? Images.iconVizParolaActiv : Images.iconVizParolaInactiv}
								className={styles.smallIcon}
							/>
						</div>
					</div>
				</div>
				<div className={styles.forgotPasswordContainer}>
					<Link to={routes.forgotPassword}>
						<a className={styles.forgotPassword}>
							Ai uitat parola?
						</a>
					</Link>

				</div>
				<div className={styles.loginButtonContainer}>
					<div
						className={styles.loginButton}
						onClick={() => {
							login(emailDraft, passwordDraft);
						}}>
						<span className={styles.loginButtonText}>ACCESEAZĂ CONTUL</span>
					</div>
				</div>
				<div className={styles.termsAndConditionsContainer}>
					{/* <span className={styles.termsAndConditionsText}>
						Prin logarea în această aplicație vei accepta <a href="#">Termenii si Condițiile</a> și{' '}
						<a href="#">Politica de confidențialitate</a>
					</span> */}
				</div>
				{error}
				{loginInProgress && <span>Loading...</span>}
			</div>
			<div style={{ display: 'flex', justifyContent:'end', flexDirection:'column', flex:1, height:'100%' }}>
				<Footer/>
			</div>
		</div>
	);
};

const mapStateToProps = (state: Redux) => {
	const { error, loginInProgress, loginSuccess } = state.authentication;
	return { error, loginInProgress, loginSuccess };
};

const mapDispatchToProps = (dispatch: (arg0: LoginActionSaga) => void) => ({
	login: (email: string, password: string) =>
		dispatch({ type: LOGIN_SAGA, payload: { email, password } })
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
