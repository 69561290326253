import React from 'react';
import { Document, Page, View, Image, Text, StyleSheet, Font, Link } from '@react-pdf/renderer';
import Images from '../../constants/Images';
import Fonts from '../../../../fonts';

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: Fonts.Roboto.regular
        },
        {
            src: Fonts.Roboto.bold,
            fontWeight: 'bold'
        }
    ]
});

interface IProps {
    qrUrl: string;
    departament: string;
    url: string;
}

const styles = StyleSheet.create({
    bold: {
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    regular: {
        fontFamily: 'Roboto'
    },
    subtitle: {
        display: 'flex',
        flexDirection: 'row'
    },
    logo: {
        width: '80',
        height: 'auto'
    },
    qr: {
        width: '250',
        height: 'auto'
    },
    header: {
        // height: '40',
        width: '100%',
        marginTop: '10'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: '50',
        flex: 1
    },
    url: {
        fontFamily: 'Roboto',
        fontSize: '14',
        color: '#006AB3',
    },
    footer: {
        fontFamily: 'Roboto',
        fontSize: '10',
        textAlign: 'center',
        color: '#003399',
        padding: 0,
        lineHeight: 0
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '10'
    }
});

const PdfQR = ({ url, qrUrl, departament }: IProps) => {

    return (
        qrUrl ?
            <Document >
                <Page orientation={'landscape'}>
                    <View style={styles.container}>
                        <Image style={styles.header} src={Images.headerPDF} />
                        <Text style={styles.bold}>{departament}</Text>
                        <View style={styles.subtitle}>
                            <Text style={styles.regular}>{'Evaluați interacțiunea cu departamentul'}</Text>
                            <Text style={styles.bold}>{' scanând codul QR de mai jos'}</Text>
                        </View>
                        <Image style={styles.logo} src={Images.siglaPDF} />
                        <Image style={styles.qr} src={qrUrl} />
                        <Text style={styles.url}>{url}</Text>
                        <View style={styles.footerContainer}>
                            <Text style={styles.footer}>{'Această pagină web este cofinanțată din Fondul Social European prin Programul Operațional Capacitate Administrativă 2014-2020 '}<Text style={{textDecoration:'underline'}}>{'www.poca.ro'}</Text></Text>
                            <Text style={styles.footer}>{'MySMIS 152005 / SIPOCA 1125'}</Text>
                            <Text style={styles.footer}>{'Pentru informații detaliate despre celelalte programe cofinanțate de Uniunea Europeană, vă invităm să vizitați '}<Link src={'www.fonduri-ue.ro'} style={{textDecoration:'underline'}}><Text>{'www.fonduri-ue.ro/'}</Text></Link></Text>
                            <Text style={styles.footer}>{'Conținutul acestei pagini web nu reprezintă în mod obligatoriu poziția oficială a Uniunii Europene. Întreaga responsabilitate asupra corectitudinii și coerenței informațiilor prezentate revine inițiatorilor paginii web.'}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
            : <Document />
    );
};

export default PdfQR;