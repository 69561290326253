import produce from 'immer';
import { assign } from 'lodash-es';
import {
	FETCH_STATISTICI_CORUPTIE,
	StatisticiTypes,
	FETCH_STATISTICI_INTEGRITATE,
	FETCH_STATISTICI_SATISFACTIE,
	LOADING_STATISTICI_CORUPTIE,
	LOADING_STATISTICI_INTEGRITATE,
	LOADING_STATISTICI_SATISFACTIE
} from './actionTypes';
import { InitialState } from './payloadTypes';

const INITIAL_STATE: InitialState = {
	statisticiCoruptie: [],
	statisticiIntegritate: [],
	statisticiSatisfactie: [],
	loadingCoruptie: false,
	loadingIntegritate: false,
	loadingSatisfactie: false,
	error: ''
};

// all authentication cases have the exact same code consider refactoring

export default (state = INITIAL_STATE, action: StatisticiTypes) => {
	switch (action.type) {
		case FETCH_STATISTICI_CORUPTIE:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					statisticiCoruptie: action.payload.statisticiCoruptie,
				});
			}
			);
		case FETCH_STATISTICI_INTEGRITATE:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					statisticiIntegritate: action.payload.statisticiIntegritate,
				});
			}
			);
		case FETCH_STATISTICI_SATISFACTIE:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					statisticiSatisfactie: action.payload.statisticiSatisfactie,
				});
			}
			);
		case LOADING_STATISTICI_CORUPTIE:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					loadingCoruptie: action.payload.loadingCoruptie,
				});
			}
			);
		case LOADING_STATISTICI_INTEGRITATE:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					loadingIntegritate: action.payload.loadingIntegritate,
				});
			}
			);
		case LOADING_STATISTICI_SATISFACTIE:
			return produce(state, (nextState: InitialState) => {
				assign(nextState, {
					loadingSatisfactie: action.payload.loadingSatisfactie,
				});
			}
			);
		default:
			return state;
	}
};
