import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Images from '../../constants/Images';
import styles from './styles.module.css';
import generalStyles from '../../../../generalStyles/generalStyles.module.css';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
// import Stele from '../../components/Stele/Stele';

interface DepartamenteType {
	idDepartamentCid: string,
	denumire: string,
	urlChestionar: string
}

interface ComentariiType {
	data_completare: string,
	continut: string,
	scor_satisfactie: string
}

// interface RaspunsType{
// 	numarRaspunsuri: number;
// 	numarAltceva: number;
// 	raspunsuri:{
// 	data: string[]
// 	}
// }

const HomeScreen = () => {

	const [totalCompletate, setTotalCompletate] = useState<number>(0);
	const [departamente, setDepartamente] = useState<DepartamenteType[]>([]);
	const [comentarii, setComentarii] = useState<ComentariiType[]>([]);
	const [limitComentarii, setLimitComentarii] = useState<number>(3);
	const [loader, setLoader] = useState<boolean>(false);

	const [departamentSelectat, setDepartamentSelectat] = useState<string>('0');
	const [departamentSelectatText, setDepartamentSelectatText] = useState<string>('Toate departamentele');
	const [departamentSelectatTextTemp, setDepartamentSelectatTextTemp] = useState<string>('');
	const [anSelectat, setAnSelectat] = useState<string>(new Date().getFullYear().toString());
	const [anTextSelectat, setAnTextSelectat] = useState<string>('pentru anul în curs');
	const [anTextSelectatTemp, setAnTextSelectatTemp] = useState<string>('');
	const [imagineSatisfactie, setImagineSatisfactie] = useState<string>('');
	const [imagineCoruptie, setImagineCoruptie] = useState<string>('');
	const [imagineIntegritate, setImagineIntegritate] = useState<string>('');
	const [statisticaSatisfactieDepartament, setStatisticaSatisfactieDepartament] = useState<number>(0);
	const [statisticaCoruptieDepartament, setStatisticaCoruptieDepartament] = useState<number>(0);
	const [statisticaIntegritateDepartament, setStatisticaIntegritateDepartament] = useState<number>(0);

	const onClickFilter = () => {
		setLimitComentarii(3);
		if (anTextSelectatTemp) {
			setAnTextSelectat(anTextSelectatTemp);
		}
		if (departamentSelectatTextTemp) {
			setDepartamentSelectatText(departamentSelectatTextTemp);
		}
		const depart = departamente.find(d => d.idDepartamentCid === departamentSelectat);
		const idDepart = depart?.idDepartamentCid || departamentSelectat;
		setLoader(true);
		Promise.all([
			axios.post(`${process.env.REACT_APP_BACKEND_URL}/statistica_satisfactie`, {
				id: idDepart,
				// an: new Date().getFullYear()
				an: anSelectat
			}).then((data) => setStatisticaSatisfactieDepartament(data.data.detail.data.satisfactie)),
			axios.post(`${process.env.REACT_APP_BACKEND_URL}/statistica_coruptie`, {
				id: idDepart,
				an: anSelectat
			}).then((data) => setStatisticaCoruptieDepartament(data.data.detail.data.coruptie)),
			axios.post(`${process.env.REACT_APP_BACKEND_URL}/statistica_integritate`, {
				id: idDepart,
				an: anSelectat
			}).then((data) => setStatisticaIntegritateDepartament(data.data.detail.data.integritate)),

			axios.post(`${process.env.REACT_APP_BACKEND_URL}/comentarii`, {
				idDepartament: idDepart,
				an: anSelectat
			}).then((data) => { setComentarii(data.data.detail.data); }),

			axios.post(`${process.env.REACT_APP_BACKEND_URL}/statistici_answers_set`, {
				idDepartament: idDepart,
				an: anSelectat
			}).then((data) => setTotalCompletate(data.data.detail.data.nrTotal))
		])
			.then(() => setLoader(false))
			.catch(() => setLoader(false));

	};

	function getImagineSatisfactieComentariu(scorSatisfactieComentariu: number) {
		let imagine;
		if (scorSatisfactieComentariu && scorSatisfactieComentariu < 0.2) {
			imagine = Images.imgSatisfactieScazut;
		}
		else if (scorSatisfactieComentariu > 0.2 && scorSatisfactieComentariu <= 0.4) {
			imagine = Images.imgSatisfactieMediu1;
		}
		else if (scorSatisfactieComentariu > 0.4 && scorSatisfactieComentariu <= 0.6) {
			imagine = Images.imgSatisfactieMediu2;
		}
		else if (scorSatisfactieComentariu > 0.6 && scorSatisfactieComentariu <= 0.8) {
			imagine = Images.imgSatisfactieMediu3;
		}
		else if (scorSatisfactieComentariu > 0.8 && scorSatisfactieComentariu <= 1) {
			imagine = Images.imgSatisfactieCrescut;
		}
		else if (!scorSatisfactieComentariu) {
			imagine = Images.imgSatisfactieDefault;
		}
		return imagine;
	}

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_BACKEND_URL}/departamente`).then((data) => setDepartamente(data.data.detail.data));
		onClickFilter();
	}, []);

	useEffect(() => {
		// if (statisticaCoruptieDepartament === null) {
		// 	setImagine(Images.iconProfileReview);
		// }
		// else 
		if (statisticaSatisfactieDepartament && statisticaSatisfactieDepartament < 0.2) {
			setImagineSatisfactie(Images.imgSatisfactieScazut);
		}
		else if (statisticaSatisfactieDepartament > 0.2 && statisticaSatisfactieDepartament <= 0.4) {
			setImagineSatisfactie(Images.imgSatisfactieMediu1);
		}
		else if (statisticaSatisfactieDepartament > 0.4 && statisticaSatisfactieDepartament <= 0.6) {
			setImagineSatisfactie(Images.imgSatisfactieMediu2);
		}
		else if (statisticaSatisfactieDepartament > 0.6 && statisticaSatisfactieDepartament <= 0.8) {
			setImagineSatisfactie(Images.imgSatisfactieMediu3);
		}
		else if (statisticaSatisfactieDepartament > 0.8 && statisticaSatisfactieDepartament <= 1) {
			setImagineSatisfactie(Images.imgSatisfactieCrescut);
		}
		else if (!statisticaSatisfactieDepartament) {
			setImagineSatisfactie(Images.imgSatisfactieDefault);
		}

		if (statisticaCoruptieDepartament && statisticaCoruptieDepartament < 0.2) {
			setImagineCoruptie(Images.imgEvaluareScazut);
		}
		else if (statisticaCoruptieDepartament > 0.2 && statisticaCoruptieDepartament <= 0.4) {
			setImagineCoruptie(Images.imgEvaluareMediu1);
		}
		else if (statisticaCoruptieDepartament > 0.4 && statisticaCoruptieDepartament <= 0.6) {
			setImagineCoruptie(Images.imgEvaluareMediu2);
		}
		else if (statisticaCoruptieDepartament > 0.6 && statisticaCoruptieDepartament <= 0.8) {
			setImagineCoruptie(Images.imgEvaluareMediu3);
		}
		else if (statisticaCoruptieDepartament > 0.8 && statisticaCoruptieDepartament <= 1) {
			setImagineCoruptie(Images.imgEvaluareCrescut);
		}
		else if (!statisticaCoruptieDepartament) {
			setImagineCoruptie(Images.imgSatisfactieDefault);
		}

		if (statisticaIntegritateDepartament && statisticaIntegritateDepartament < 0.2) {
			setImagineIntegritate(Images.imgEvaluareScazut);
		}
		else if (statisticaIntegritateDepartament > 0.2 && statisticaIntegritateDepartament <= 0.4) {
			setImagineIntegritate(Images.imgEvaluareMediu1);
		}
		else if (statisticaIntegritateDepartament > 0.4 && statisticaIntegritateDepartament <= 0.6) {
			setImagineIntegritate(Images.imgEvaluareMediu2);
		}
		else if (statisticaIntegritateDepartament > 0.6 && statisticaIntegritateDepartament <= 0.8) {
			setImagineIntegritate(Images.imgEvaluareMediu3);
		}
		else if (statisticaIntegritateDepartament > 0.8 && statisticaIntegritateDepartament <= 1) {
			setImagineIntegritate(Images.imgEvaluareCrescut);
		}
		else if (!statisticaIntegritateDepartament) {
			setImagineIntegritate(Images.imgSatisfactieDefault);
		}
	}, [statisticaSatisfactieDepartament, statisticaCoruptieDepartament, statisticaIntegritateDepartament]);
	console.log('-----');
	console.log('coruptie: ' + statisticaCoruptieDepartament);
	console.log('satisfactie: ' + statisticaSatisfactieDepartament);
	console.log('integritate: ' + statisticaIntegritateDepartament);

	return (
		<div className={generalStyles.container}>
			<Row>
				<Col lg={12} sm={12}>
					<div className={styles.headerContainer}>
						<img src={Images.iconEvaluareaServiciilor} className={[generalStyles.headerIcon, styles.iconEvaluareaServiciilor].join(' ')} />
						<div className={generalStyles.headerTextsContainer}>
							<span className={styles.header}>Evaluarea satisfacției cetățenilor și calitatea serviciilor</span>
							<span className={styles.subHeader}>Furnizate de instituția selectată</span>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={12} lg={4} xs={12}>
					<div className={generalStyles.inputContainer}>
						<img src={Images.iconMeniuDepartament} className={generalStyles.smallIcon} />
						<Form.Select size='lg' aria-label="Departament dorit"
							className={[generalStyles.input, styles.formSelectStyle1].join(' ')}
							value={departamentSelectat}
							onChange={(e) => { setDepartamentSelectat(e.target.value); setDepartamentSelectatTextTemp(e.target.options[e.target.selectedIndex].text); }}>
							<option value="0">Toate departamentele</option>
							{
								departamente.map(departament =>
									<option key={`${departament.idDepartamentCid}--departament`} value={departament.idDepartamentCid}>{departament.denumire}</option>
								)
							}
						</Form.Select>
					</div>
				</Col>
				<Col sm={12} md={12} lg={4} xs={12}>
					<div className={generalStyles.inputContainer}>
						<img src={Images.iconCalendar} className={generalStyles.smallIcon} />
						<Form.Select size='lg' aria-label="An Statistica"
							className={[generalStyles.input, styles.formSelectStyle1].join(' ')}
							value={anSelectat}
							onChange={(e) => { setAnSelectat(e.target.value); setAnTextSelectatTemp(e.target.options[e.target.selectedIndex].text.toLocaleLowerCase()); }}>
							<option value={new Date().getFullYear()}>Pentru anul în curs</option>
							<option value="0">Pentru toți anii</option>
						</Form.Select>
					</div>
				</Col>

				<Col lg={4} xs={12}>
					<div className={styles.filterButtonSpace}>
						<div className={styles.filterButton} onClick={onClickFilter}>
							<span className={styles.filterButtonText}>FILTREAZĂ REZULTATE</span>
						</div>
					</div>
				</Col>
			</Row>
			<Row className={styles.departamentChestionareBox}>
				<Col lg={10} sm={12} >
					<p className={styles.chestionareDepartamentText}>{departamentSelectatText}</p>
				</Col>
				<Col lg={2} sm={12} >
					<p className={styles.chestionareCompletedNumber}>{totalCompletate}</p>
					<p className={styles.chestionareCompletedText}>Chestionare Completate</p>
				</Col>
			</Row>
			<Row>
				<Col lg={4} sm={12} className={styles.boxV1}>
					<p className={styles.boxTitleV1}>Gradul de satisfacție al clienților {anTextSelectat}</p>
					{/* <a className={styles.boxLinkV1} href=''>Vezi pentru anul în curs</a> */}
					{/* <Stele key={item.nume} title={item.nume} value={item.val} /> */}
					<img src={imagineSatisfactie} className={styles.boxV1ImgRating} />
					{/* <p className={styles.completedNumberBox}>80</p>
					<p className={styles.completedTextBox}>Completate</p> */}
				</Col>
				<Col lg={4} sm={12} className={styles.boxV1}>
					<p className={styles.boxTitleV1}>Evaluarea riscurilor de corupție {anTextSelectat}</p>
					{/* <a className={styles.boxLinkV1} href=''>Vezi pentru toți anii</a> */}
					{/* <Stele key={item.nume} title={item.nume} value={item.val} /> */}
					<img src={imagineCoruptie} className={styles.boxV1ImgRating} />
					{/* <p className={styles.completedNumberBox}>20</p>
					<p className={styles.completedTextBox}>Completate</p> */}
				</Col>
				<Col lg={4} sm={12} className={styles.boxV1}>
					{/* <img src={Images.iconHappyFace} className={styles.imgOverBox} /> */}
					<p className={styles.boxTitleV1}>Evaluarea incidentelor de integritate {anTextSelectat}</p>
					{/* <a className={styles.boxLinkV1} href=''>Vezi pentru toți anii</a> */}
					<img src={imagineIntegritate} className={styles.boxV1ImgRating} />
					{/* <p className={styles.completedNumberBox}>20</p>
					<p className={styles.completedTextBox}>Completate</p> */}
					{/* <h2 className={styles.boxH2V2}>Rezultatul evaluării satisfăcător.</h2> */}
					{/* <p className={styles.boxPV2}>Percepția cetățenilor cu privire la riscurile aferente corupției și integrității la nivelul Municipiului este una pozitivă.</p> */}
				</Col>
			</Row>
			{
				comentarii && comentarii.filter((comentariu, idx) => idx < limitComentarii).map((comentariu) => (
					<Row className={styles.reviewClientBox} key={comentariu.data_completare}>
						<Col lg={4} sm={12}>
							<div className={styles.divProfileReview}>
								<img src={Images.iconProfileReview} className={styles.imgProfileReview} />
							</div>
							<div className={styles.infoProfileReview}>
								<p className={styles.reviewClientBoxTitle}>{comentariu.data_completare}</p>
								<img src={getImagineSatisfactieComentariu(parseFloat(comentariu.scor_satisfactie))} className={styles.reviewClientImgRating} />
							</div>
						</Col>
						<Col lg={8} sm={12}>
							<p className={styles.reviewClientBoxText}>{comentariu.continut}</p>
						</Col>
					</Row>
				))

			}
			<Row>
				{comentarii.length && limitComentarii < comentarii.length ? <Button className={styles.showMoreButton} size="sm" onClick={() => setLimitComentarii(limitComentarii + 3)}>Arată mai mult</Button> : null}
			</Row>
			{loader && <div style={{ backgroundColor: '#cecece', opacity: 0.5, width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0 }}>
				<div className={styles.loader}></div>
			</div>}
		</div>
	);
};

export default HomeScreen;
