import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Images from '../../constants/Images';
import { LogOutActionSaga, LOGOUT_SAGA } from '../../store/authentication/actionTypes';
import { Redux } from '../../store/store';
import routes from '../routes';
import styles from './styles.module.css';
interface IProps {
	loginSuccess: boolean;
	logout: () => void;
}

const Sidebar = ({ loginSuccess, logout }: IProps) => {
	const [activeRoute, setActiveRoute] = useState<string>('');

	return (
		<div className={styles.container}>
			<div className={styles.imageContainer}>
				<img src={Images.siglaReghin} className={styles.logo} />
			</div>
			<div className={styles.linksContainer}>
				{loginSuccess && (
					<>
						<div className={styles.linkRow}>
							<NavLink to={routes.creareChestionar}
								isActive={(match) => {
									if (match) {
										setActiveRoute(routes.creareChestionar);
									}
									return match ? true : false;
								}}
								className={activeRoute === routes.creareChestionar ? styles.linkTextActive : styles.linkText}>
								<img src={activeRoute === routes.creareChestionar ? Images.iconMeniuSurveyActive : Images.iconMeniuSurvey} className={styles.linkImage} />
								{'Creare Chestionar'}
							</NavLink>
						</div>
						<div className={styles.linkRow}>
							<NavLink to={routes.atribuireDepartament}
								isActive={(match) => {
									if (match) {
										setActiveRoute(routes.atribuireDepartament);
									}
									return match ? true : false;
								}}
								className={activeRoute === routes.atribuireDepartament ? styles.linkTextActive : styles.linkText}>
								<img src={activeRoute === routes.atribuireDepartament ? Images.iconMeniuDepartamentActive : Images.iconMeniuDepartament} className={styles.linkImage} />
								{'Atribuire departament'}
							</NavLink>
						</div>
						<div className={styles.linkRow}>
							<NavLink to={routes.statistici} 
								isActive={(match) => {
									if (match) {
										setActiveRoute(routes.statistici);
									}
									return match ? true : false;
								}}
								className={activeRoute === routes.statistici ? styles.linkTextActive : styles.linkText}>
								<img src={activeRoute === routes.statistici ? Images.iconMeniuStatisticiActive : Images.iconMeniuStatistici} className={styles.linkImage} />
								{'Statistici'}
							</NavLink>
						</div>
						<div className={styles.linkRow}>
						<NavLink to={routes.comentarii} 
								isActive={(match) => {
									if (match) {
										setActiveRoute(routes.comentarii);
									}
									return match ? true : false;
								}}
								className={activeRoute === routes.comentarii ? styles.linkTextActive : styles.linkText}>
								<img src={activeRoute === routes.comentarii ? Images.iconMeniuComentariiActive : Images.iconMeniuComentarii} className={styles.linkImage} />
								{'Comentarii'}
							</NavLink>
						</div>
						<div className={styles.linkRow}>
							<img src={Images.iconMeniuPortal} className={styles.linkImage} />
							<a href={process.env.REACT_APP_URL_PORTAL ?? ''} className={styles.linkText}>
								{'Inapoi la portal'}
							</a>
						</div>
						<div className={styles.linkRow}>
							<img src={Images.iconMeniuLogout} className={styles.linkImage} />
							<NavLink
								onClick={(e) => {
									e.preventDefault();
									logout();
								}}
								to={''}
								className={styles.linkText}>
								{'Delogare'}
							</NavLink>
						</div>
					</>
				)}
				{!loginSuccess && (
					<>
						<div className={styles.linkRow}>
							<img src={Images.iconMeniuLogout} className={styles.linkImage} />
							<NavLink to={window.location.href.toLocaleLowerCase().includes('changepassword')?'../'+routes.login:routes.login} className={(isActive: boolean) => isActive ? styles.linkTextActive : styles.linkText}>
								{'Login'}
							</NavLink>
						</div>
						<div className={styles.linkRow}>
							<img src={Images.iconMeniuPortal} className={styles.linkImage} />
							<a href={process.env.REACT_APP_URL_PORTAL??''} className={styles.linkText}>
								{'Inapoi la portal'}
							</a>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state: Redux) => {
	const { loginSuccess } = state.authentication;
	return { loginSuccess };
};

const mapDispatchToProps = (dispatch: (arg0: LogOutActionSaga) => void) => ({
	logout: () => dispatch({ type: LOGOUT_SAGA, payload: null })
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
