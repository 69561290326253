import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Images from '../../constants/Images';
import { LogOutActionSaga, LOGOUT_SAGA } from '../../store/authentication/actionTypes';
import { Redux } from '../../store/store';
import routes from '../routes';
import styles from './styles.module.css';

const Sidebar = () => {
	return (
		<div className={styles.container}>
			<div className={styles.imageContainer}>
				<img src={Images.siglaReghin} className={styles.logo} />
			</div>
			<div className={styles.linksContainer}>
				<div className={styles.linkRow}>
					<Link to={routes.root} className={styles.linkText}>
						<img src={Images.iconMeniuSurvey} className={styles.linkImage} />
						{'Evaluarea satisfacției'}
					</Link>
				</div>
				<div className={styles.linkRow}>
					<a href={process.env.REACT_APP_URL_PORTAL} className={styles.linkText}>
						<img src={Images.iconMeniuPortal} className={styles.linkImage} />
						{'Înapoi la portal'}	
					</a>
				</div>


			</div>

			{/* <p>  
				<a className="btn btn-primary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">    Link with href</a>  
				<button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">    Button with data-bs-target</button>
			</p>
				<div className="collapse" id="collapseExample">  
					<div className="card card-body">
						Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
					</div>
				</div> */}

			{/* <div
				id="sidebarMenu"
				className="collapse d-lg-block sidebar collapse bg-white"
			>
				<div className="position-sticky">
					<div className="list-group list-group-flush mx-3 mt-4">
						<a
							className="list-group-item list-group-item-action py-2 ripple"
							aria-current="true"
							data-mdb-toggle="collapse"
							href="#collapseExample1"
							aria-expanded="true"
							aria-controls="collapseExample1"
						>
							<i className="fas fa-tachometer-alt fa-fw me-3"></i
							><span>Expanded menu</span>
						</a>
						<a
							className="list-group-item list-group-item-action py-2 ripple"
							aria-current="true"
							data-mdb-toggle="collapse"
							href="#collapseExample2"
							aria-expanded="true"
							aria-controls="collapseExample2"
						>
							<i className="fas fa-chart-area fa-fw me-3"></i
							><span>Collapsed menu</span>
						</a>

					</div>
				</div>
			</div>
			<div id="main-navbar"
				className="navbar navbar-expand-lg navbar-light bg-white fixed-top"
			>
				<div className="container-fluid">
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#main-navbar"
						aria-controls="main-navbar"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<i className="fas fa-bars"></i>
						<img src={Images.iconMeniuSurvey} className={styles.linkImage} />
					</button>
				</div>
			</div> */}
		</div>
	);
};

const mapStateToProps = (state: Redux) => {
	const { loginSuccess } = state.authentication;
	return { loginSuccess };
};

const mapDispatchToProps = (dispatch: (arg0: LogOutActionSaga) => void) => ({
	logout: () => dispatch({ type: LOGOUT_SAGA, payload: null })
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
