import { put, takeLatest, call } from '@redux-saga/core/effects';
import axios from 'axios';
import { LOGIN, LOGIN_SAGA, LOGOUT } from '.';
import { LoginActionSaga } from '.';

import {
	ERROR,
	LOGIN_SUCCESSFUL,
	LOGOUT_SAGA,
	LOGOUT_FAILURE,
	LoginSuccessType,
	LoginType,
	LOADING_AUTH,
	RESET_SAGA,
	ResetActionSaga,
	RESET_SUCCESSFUL,
	RESET_ERROR,
	ChangeActionSaga,
	CHANGE_SUCCESSFUL,
	CHANGE_ERROR,
	CHANGE_SAGA,
	CHECK_SAGA
} from './actionTypes';

export const tokenReset = async (token: string): Promise<string> => {
	return axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/token_reset`, {
	},{
		headers: {
			'tkn': token
		}
	}).then((data)=>{
		return data.data.detail.token;
	}).catch(()=>'-1');
};

const login = async ({ email, password }: LoginType): Promise<LoginSuccessType> => {
	return axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/login`, {
		username: email,
		password: password
	}).then((resp) => {
		return {
			loginSuccess: resp.status === 200 && resp.data.detail.success,
			message: resp.data.detail.message,
			token: resp.data.detail.token,
			userId: email
		};
	}).catch((e) => {
		return {
			loginSuccess: false,
			message: e.response.data.detail.message,
			userId: '',
			token: ''
		};
	});
};

const reset = async ({ user }: { user: string }) => {
	return axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/password_reset`, {
		user,
		url: process.env.REACT_APP_FRONTEND_URL ?? ''
	}).then(() => ({
		resetSuccess: true,
		message: ''
	})).catch(e => {
		return ({
			resetSuccess: false,
			message: e.response.data.detail.error
		});
	});
};

const change = async ({ password, passwordConfirm, userParam }: { password: string, passwordConfirm: string, userParam: string }) => {
	return axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/password_change`, {
		password,
		passwordConfirm,
		userParam
	}).then(() => ({
		changeSuccess: true,
		message: ''
	})).catch(e => {
		return ({
			changeSuccess: false,
			message: e.response.data.detail.error
		});
	});
};

const check = async ({ userParam }: { userParam: string }) => {
	return axios.post(`${process.env.REACT_APP_BACKEND_URL_ADMIN}/check_password_change`, {
		userParam
	}).then(() => ({
		checkSuccess: true,
		message: ''
	})).catch(e => {
		return ({
			checkSuccess: false,
			message: e.response.data.detail.error
		});
	});
};



//worker saga
function* loginRequest(action: LoginActionSaga) {
	try {
		yield put({ type: LOADING_AUTH, payload: true });
		const { loginSuccess, userId, message, token } = yield call(login, action.payload);
		if (!loginSuccess) {
			yield put({ type: LOGIN_SUCCESSFUL, payload: { loginSuccess: false } });
			yield put({ type: ERROR, payload: { error: message } });
		}
		else{
			yield put({ type: LOGIN_SUCCESSFUL, payload: { loginSuccess, userId, token } });
		}
		yield put({ type: LOADING_AUTH, payload: false });
	} catch (error) {
		yield put({ type: LOADING_AUTH, payload: false });
		const payload: { userId: string; token: null | string; error: string } = {
			userId: '',
			token: null,
			error: 'A aparut o eroare'
		};
		yield put({ type: LOGIN, payload });
		yield put({ type: ERROR, payload: { error: 'A aparut o eroare.' } });
		yield put({ type: LOGIN_SUCCESSFUL, payload: { loginSuccess: false } });
	}
}

function* logoutRequest() {
	try {
		yield put({ type: LOGOUT });
	} catch (error) {
		yield put({ type: LOGOUT_FAILURE, payload: { logoutError: true } });
	}
}

function* resetRequest(action: ResetActionSaga) {
	try {
		yield put({ type: LOADING_AUTH, payload: true });
		const { resetSuccess, message } = yield call(reset, action.payload);
		yield put({ type: RESET_SUCCESSFUL, payload: { resetSuccess: resetSuccess } });
		yield put({ type: RESET_ERROR, payload: { error: message } });
		yield put({ type: LOADING_AUTH, payload: false });
	} catch (error) {
		yield put({ type: RESET_SUCCESSFUL, payload: { resetSuccess: false } });
		yield put({ type: RESET_ERROR, payload: { error: 'A aparut o eroare' } });
		yield put({ type: LOADING_AUTH, payload: false });
	}
}

function* changeRequest(action: ChangeActionSaga) {
	try {
		yield put({ type: LOADING_AUTH, payload: true });
		const { changeSuccess, message } = yield call(change, action.payload);
		yield put({ type: CHANGE_SUCCESSFUL, payload: { changeSuccess: changeSuccess } });
		yield put({ type: CHANGE_ERROR, payload: { error: message } });
		yield put({ type: LOADING_AUTH, payload: false });
	} catch (error) {
		yield put({ type: RESET_SUCCESSFUL, payload: { changeSuccess: false } });
		yield put({ type: RESET_ERROR, payload: { error: 'A aparut o eroare' } });
		yield put({ type: LOADING_AUTH, payload: false });
	}
}

function* checkRequest(action: ChangeActionSaga) {
	try {
		yield put({ type: LOADING_AUTH, payload: true });
		yield put({ type: CHANGE_ERROR, payload: { error: '' } });
		yield put({ type: ERROR, payload: { error: '' } });
		yield put({ type: RESET_ERROR, payload: { error: '' } });
		const { checkSuccess, message } = yield call(check, action.payload);
		if(!checkSuccess){
			yield put({ type: CHANGE_SUCCESSFUL, payload: { changeSuccess: false } });
			yield put({ type: CHANGE_ERROR, payload: { error: message } });
			yield put({ type: LOADING_AUTH, payload: false });
		}
		yield put({ type: LOADING_AUTH, payload: false });
	} catch (error) {
		yield put({ type: CHANGE_SUCCESSFUL, payload: { changeSuccess: false } });
		yield put({ type: CHANGE_ERROR, payload: { error: 'A aparut o eroare' } });
		yield put({ type: LOADING_AUTH, payload: false });
	}
}

//watcher saga
export function* watchLogin() {
	yield takeLatest(LOGIN_SAGA, loginRequest);
}

export function* watchLogOut() {
	yield takeLatest(LOGOUT_SAGA, logoutRequest);
}

export function* watchReset() {
	yield takeLatest(RESET_SAGA, resetRequest);
}

export function* watchChange() {
	yield takeLatest(CHANGE_SAGA, changeRequest);
}

export function* watchCheck() {
	yield takeLatest(CHECK_SAGA, checkRequest);
}