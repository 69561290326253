import React from 'react';
import styles from './styles.module.css';

interface IProps {
    children: React.ReactElement;
    visible: boolean;
    hide: ()=>void;
}

const Modal = ({ children, visible, hide }: IProps) => {
	return visible ? (
		<div className={styles.container} onClick={hide}>
			<div className={styles.childrenWrapper}>
				<div style={{marginTop:-30, marginBottom:15, fontSize:18, fontWeight:'bold', textAlign:'right', cursor:'pointer'}}>x</div>
				{children}
			</div>
		</div>
	)
		: null;
};

export default Modal;