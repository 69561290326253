//Login
import iconLogin from '../../../assets/iconLogin.svg';
import iconEmail from '../../../assets/icon-email.svg';
import iconParola from '../../../assets/icon-parola.svg';
import iconVizParolaActiv from '../../../assets/icon-vizparola-activ.svg';
import iconVizParolaInactiv from '../../../assets/icon-vizparola-inactiv.svg';

//topbar
import iconSearch from '../../../assets/icon-search-website-normal.svg';
import iconTheme from '../../../assets/icon-change-text-color-normal.svg';
import iconFont from '../../../assets/icon-change-text-dimension-normal.svg';
import siglaReghin from '../../../assets/siglaReghin.svg';
import iconMeniuNavbar from '../../../assets/menu-icon.png';
import euLogo from '../../../assets/icon-eu.png';
import isLogo from '../../../assets/icon-is.png';
import guvRoLogo from '../../../assets/icon-guv-ro.png';


//sidebar
import iconMeniuSurvey from '../../../assets/iconMeniuSurvey.svg';
import iconMeniuSurveyActive from '../../../assets/icon-survey-active.svg';
import iconMeniuDepartament from '../../../assets/icon-link-depart-normal.svg';
import iconMeniuDepartamentActive from '../../../assets/icon-link-depart-active.svg';
import iconMeniuStatistici from '../../../assets/icon-statistics-normal.svg';
import iconMeniuStatisticiActive from '../../../assets/icon-statistics-active.svg';
import iconMeniuComentarii from '../../../assets/icon-comments-meniu.svg';
import iconMeniuComentariiActive from '../../../assets/icon-comments-active.svg';
import iconMeniuLogout from '../../../assets/icon-logout-normal.svg';
import iconMeniuPortal from '../../../assets/icon-back-to-website-normal.svg';

//Atribuire departament
import iconSelectieDepartament from '../../../assets/icon-selection-institution.png';
import iconLink from '../../../assets/icon-link.png';
import iconAtribuireDepartament from '../../../assets/icon-link-depart-title.png';

//Statistici
import iconStatistici from '../../../assets/icon-statistics-title.svg';
import iconStarFilled from '../../../assets/icon-star-filled.svg';
import iconStarHalfFileed from '../../../assets/icon-star-half-filled.svg';
import iconStarEmpty from '../../../assets/icon-star-empty.svg';
import iconHours from '../../../assets/icon-hours.svg';
import imgEvaluareScazut from '../../../assets/evaluare-scazut.svg';
import imgEvaluareMediu1 from '../../../assets/evaluare-mediu-1.svg';
import imgEvaluareMediu2 from '../../../assets/evaluare-mediu-2.svg';
import imgEvaluareMediu3 from '../../../assets/evaluare-mediu-3.svg';
import imgEvaluareCrescut from '../../../assets/evaluare-crescut.svg';
import imgSatisfactieDefault from '../../../assets/satisfactie-nul.svg';
import imgSatisfactieScazut from '../../../assets/satisfactie-scazut.svg';
import imgSatisfactieMediu1 from '../../../assets/satisfactie-mediu-1.svg';
import imgSatisfactieMediu2 from '../../../assets/satisfactie-mediu-2.svg';
import imgSatisfactieMediu3 from '../../../assets/satisfactie-mediu-3.svg';
import imgSatisfactieCrescut from '../../../assets/satisfactie-crescut.svg';

//Comentarii
import iconComentarii from '../../../assets/icon-comments-normal.svg';

//others
import UpArrow from '../../../assets/plain-triangle.png';

//pdf
import headerPDF from '../../../assets/headerPDF.png';
import siglaPDF from '../../../assets/siglaPDF.png';

const Images = {
	logo: require('../../../assets/logo192.png'),
	pocaLogo: require('../../../assets/pocaLogo.png'),
	pocaLogo2x: require('../../../assets/pocaLogo@2x.png'),
	reginLogo: require('../../../assets/reghinLogo.png'),
	reginLogo2x: require('../../../assets/reghinLogo@2x.png'),
	isLogo,
	guvRoLogo,
	euLogo,
	siglaReghin,
	iconLogin,
	iconEmail,
	iconParola,
	iconVizParolaActiv,
	iconVizParolaInactiv,
	iconSearch,
	iconTheme,
	iconFont,
	iconMeniuDepartament,
	iconMeniuSurvey,
	iconMeniuStatistici,
	iconMeniuComentarii,
	iconMeniuDepartamentActive,
	iconMeniuSurveyActive,
	iconMeniuStatisticiActive,
	iconMeniuComentariiActive,
	iconMeniuLogout,
	iconMeniuPortal,
	iconSelectieDepartament,
	iconLink,
	iconAtribuireDepartament,
	iconStatistici,
	iconStarEmpty,
	iconStarFilled,
	iconStarHalfFileed,
	iconHours,
	iconComentarii,
	UpArrow,
	imgEvaluareScazut,
	imgEvaluareMediu1,
	imgEvaluareMediu2,
	imgEvaluareMediu3,
	imgEvaluareCrescut,
	imgSatisfactieDefault,
	imgSatisfactieScazut,
	imgSatisfactieMediu1,
	imgSatisfactieMediu2,
	imgSatisfactieMediu3,
	imgSatisfactieCrescut,
	iconMeniuNavbar,
	headerPDF,
	siglaPDF
};

export default Images;
