import storage from 'redux-persist/lib/storage';
import { spawn } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { authenticationReducer } from './authentication';
import { departamenteReducer } from './departamente';
import { watchChange, watchCheck, watchLogin, watchLogOut, watchReset } from './authentication/actions';
import { watchAtribuireDepartamente, watchFetchDepartamente } from './departamente';
import { comentariiReducer, watchAcceptComentarii, watchFetchComentarii } from './comentarii';
import { statisticiReducer, watchFetchCoruptie, watchFetchIntegritate, watchFetchSatisfactie } from './statistici';

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
	yield spawn(watchLogin);
	yield spawn(watchLogOut);
	yield spawn(watchFetchDepartamente);
	yield spawn(watchAtribuireDepartamente);
	yield spawn(watchFetchComentarii);
	yield spawn(watchAcceptComentarii);
	yield spawn(watchFetchCoruptie);
	yield spawn(watchFetchIntegritate);
	yield spawn(watchFetchSatisfactie);
	yield spawn(watchReset);
	yield spawn(watchChange);
	yield spawn(watchCheck);
}

const rootReducer = combineReducers({
	authentication: authenticationReducer,
	departamente: departamenteReducer,
	comentarii: comentariiReducer,
	statistici: statisticiReducer
});

const persistConfig = {
	key: 'root',
	whitelist: ['authentication','departamente','comentarii', 'statistici'],
	storage: storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export type Redux = ReturnType<typeof persistedReducer>;

export const store: Store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
