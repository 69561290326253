import React, { useEffect, useState } from 'react';
import Images from '../../constants/Images';

interface IProps {
    score: number;
}


const ValoriStatistici = ({ score }: IProps) => {
	const [imagineSatisfactie, setImagineSatisfactie] = useState<string>();

	useEffect(() => {
		if (score && score < 0.2) {
			setImagineSatisfactie(Images.imgSatisfactieScazut);
		}
		else if (score > 0.2 && score <= 0.4) {
			setImagineSatisfactie(Images.imgSatisfactieMediu1);
		}
		else if (score > 0.4 && score <= 0.6) {
			setImagineSatisfactie(Images.imgSatisfactieMediu2);
		}
		else if (score > 0.6 && score <= 0.8) {
			setImagineSatisfactie(Images.imgSatisfactieMediu3);
		}
		else if (score > 0.8 && score <= 1) {
			setImagineSatisfactie(Images.imgSatisfactieCrescut);
		}
		else if (!score) {
			setImagineSatisfactie(Images.imgSatisfactieDefault);
		}
	}, [score]);

	return (
		<img src={imagineSatisfactie} />
	);
};

export default ValoriStatistici;
