//topbar
import iconSearch from '../../../assets/icon-search-website-normal.svg';
import iconTheme from '../../../assets/icon-change-text-color-normal.svg';
import iconFont from '../../../assets/icon-change-text-dimension-normal.svg';
import siglaReghin from '../../../assets/siglaReghin.svg';
import euLogo from '../../../assets/icon-eu.png';
import isLogo from '../../../assets/icon-is.png';
import guvRoLogo from '../../../assets/icon-guv-ro.png';
import iconMeniuNavbar from '../../../assets/menu-icon.png';

//sidebar
import iconMeniuSurvey from '../../../assets/iconMeniuSurvey.svg';
import iconMeniuDepartament from '../../../assets/icon-link-depart-normal.svg';
import iconMeniuStatistici from '../../../assets/icon-statistics-normal.svg';
import iconMeniuComentarii from '../../../assets/icon-comments-normal.svg';
import iconMeniuLogout from '../../../assets/icon-logout-normal.svg';
import iconMeniuPortal from '../../../assets/icon-back-to-website-normal.svg';

//first page Alin
import iconEvaluareaServiciilor from '../../../assets/icon-evaluarea-serviciilor.svg';
import iconHappyFace from '../../../assets/icon-happy-emoticon.svg';
import iconProfileReview from '../../../assets/icon-user-profile.svg';
import imgEvaluareScazut from '../../../assets/evaluare-scazut.svg';
import imgEvaluareMediu1 from '../../../assets/evaluare-mediu-1.svg';
import imgEvaluareMediu2 from '../../../assets/evaluare-mediu-2.svg';
import imgEvaluareMediu3 from '../../../assets/evaluare-mediu-3.svg';
import imgEvaluareCrescut from '../../../assets/evaluare-crescut.svg';
import imgSatisfactieDefault from '../../../assets/satisfactie-nul.svg';
import imgSatisfactieScazut from '../../../assets/satisfactie-scazut.svg';
import imgSatisfactieMediu1 from '../../../assets/satisfactie-mediu-1.svg';
import imgSatisfactieMediu2 from '../../../assets/satisfactie-mediu-2.svg';
import imgSatisfactieMediu3 from '../../../assets/satisfactie-mediu-3.svg';
import imgSatisfactieCrescut from '../../../assets/satisfactie-crescut.svg';
import iconCalendar from '../../../assets/icon-calendar.svg';

const Images = {
	logo: require('../../../assets/logo192.png'),
	pocaLogo: require('../../../assets/pocaLogo.png'),
	pocaLogo2x: require('../../../assets/icon-poca.png'),
	reginLogo: require('../../../assets/reghinLogo.png'),
	reginLogo2x: require('../../../assets/reghinLogo@2x.png'),
	euLogo,
	isLogo,
	guvRoLogo,
	iconMeniuNavbar,
	siglaReghin,
	iconSearch,
	iconTheme,
	iconFont,
	iconMeniuDepartament,
	iconMeniuSurvey,
	iconMeniuStatistici,
	iconMeniuComentarii,
	iconMeniuLogout,
	iconMeniuPortal,
	iconEvaluareaServiciilor,
	iconHappyFace,
	iconProfileReview,
	imgEvaluareScazut,
	imgEvaluareMediu1,
	imgEvaluareMediu2,
	imgEvaluareMediu3,
	imgEvaluareCrescut,
	imgSatisfactieDefault,
	imgSatisfactieScazut,
	imgSatisfactieMediu1,
	imgSatisfactieMediu2,
	imgSatisfactieMediu3,
	imgSatisfactieCrescut,
	iconCalendar
};

export default Images;
