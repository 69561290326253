export const LOGIN = 'LOGIN';
export const LOGIN_SAGA = 'LOGIN_SAGA';
export const ERROR = 'ERROR';
export const LOGOUT = 'LOGOUT';
export const TOKEN_RESET = 'TOKEN_RESET';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SAGA = 'LOGOUT_SAGA';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const RESET_SUCCESSFUL = 'RESET_SUCCESSFUL';
export const CHANGE_SUCCESSFUL = 'CHANGE_SUCCESSFUL';
export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
export const LOADING_AUTH = 'LOADING_AUTH';
export const RESET_SAGA = 'RESET_SAGA';
export const RESET_ERROR = 'RESET_ERROR';
export const CHANGE_SAGA = 'CHANGE_SAGA';
export const CHANGE_ERROR = 'CHANGE_ERROR';
export const CHECK_SAGA = 'CHECK_SAGA';

//reducer actions
type LoginAction = {
	type: typeof LOGIN;
	payload: {
		userId: string;
		token: string | null;
	};
};

type ErrorAction = {
	type: typeof ERROR;
	payload: ErrorType;
};

type ResetErrorAction = {
	type: typeof RESET_ERROR;
	payload: ErrorType;
};

type ChangeErrorAction = {
	type: typeof CHANGE_ERROR;
	payload: ErrorType;
};

type LogOutAction = {
	type: typeof LOGOUT;
	payload: null;
};

export type ResetActionSaga = {
	type: typeof RESET_SAGA;
	payload: {
		user: string
	}
}

export type ChangeActionSaga = {
	type: typeof CHANGE_SAGA;
	payload: {
		password: string,
		passwordConfirm: string,
		userParam: string
	}
}

export type CheckActionSaga = {
	type: typeof CHECK_SAGA;
	payload: {
		userParam: string
	}
}

type LogOutFailureAction = {
	type: typeof LOGOUT_FAILURE;
	payload: LogoutFailureType;
};

type LoginSuccessAction = {
	type: typeof LOGIN_SUCCESSFUL;
	payload: LoginSuccessType;
};

type ResetSuccessAction = {
	type: typeof RESET_SUCCESSFUL;
	payload: ResetSuccessType;
};

type ChangeSuccessAction = {
	type: typeof CHANGE_SUCCESSFUL;
	payload: ChangeSuccessType;
};

//saga actions
export type LoginActionSaga = {
	type: typeof LOGIN_SAGA;
	payload: LoginType;
};

export type TokenReset = {
	type: typeof TOKEN_RESET;
	payload:{
		token: string;
	}
}

export type LogOutActionSaga = {
	type: typeof LOGOUT_SAGA;
	payload: null;
};

export type LoginInProgressAction = {
	type: typeof LOGIN_IN_PROGRESS;
	payload: boolean;
};

export type LoadingAuthAction = {
	type: typeof LOADING_AUTH;
	payload: boolean;
};

export type ErrorType = {
	error: boolean;
};

export type LoginSuccessType = {
	loginSuccess: boolean;
	userId: string;
	message: string;
	token: string;
};

export type ResetSuccessType = {
	resetSuccess: boolean;
};

export type ChangeSuccessType = {
	changeSuccess: boolean;
};

export type LoginType = {
	email: string;
	password: string;
};

export type LogoutFailureType = {
	logoutError: boolean;
};

export type AuthTypes =
	| LoadingAuthAction
	| LoginAction
	| LoginInProgressAction
	| ErrorAction
	| LoginSuccessAction
	| LogOutAction
	| LogOutFailureAction
	| ResetErrorAction
	| ResetSuccessAction
	| ChangeErrorAction
	| ChangeSuccessAction
	| TokenReset;
