import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

interface IProps {
	title: string;
	value: number;
	multicolor?: boolean;
}

const LinieChart = ({ title, value, multicolor = false }: IProps) => {
	
	const [color, setColor] = useState<string>('');

	useEffect(() => {
		if (value && value < 0.2) {
			setColor('#75ba24');
		}
		else if (value > 0.2 && value <= 0.4) {
			setColor('#c4d600');
		}
		else if (value > 0.4 && value <= 0.6) {
			setColor('#f8ba04');
		}
		else if (value > 0.6 && value <= 0.8) {
			setColor('#f97001');
		}
		else if (value > 0.8 && value <= 1) {
			setColor('#dc1f1c');
		}
		else if (!value) {
			setColor('gray');
		}
	}, [value]);

	return (
		<div className={styles.container}>
			<div className={styles.row}>
				<p className={styles.title}>{title}</p>
				<p style={{ color: color, fontWeight: 'bold' }}>{parseFloat((value * 100).toFixed(2)) + '%'}</p>
			</div>
			<div className={styles.backgroundLinie}>
				<div
					style={{
						backgroundColor: color,
						height: 5,
						width: parseFloat((value * 100).toFixed(2)) + '%'
					}}
				/>
			</div>
			<p></p>
		</div>
	);
};

export default LinieChart;
