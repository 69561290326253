import React from 'react';
import Images from '../../constants/Images';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styles from './styles.module.css';

const Topbar = () => {
	return (
		<div className={styles.container}>
			<Row className={styles.headerV1}>
				<Col lg={3} md={3} sm={3} xs={3}>
					<img src={Images.euLogo} className={[styles.logoItems, styles.euLogo].join(' ')} />
				</Col>
				<Col lg={3} md={3} sm={3} xs={3}>
					<img src={Images.guvRoLogo} className={[styles.logoItems, styles.guvRoLogo].join(' ')} />
				</Col>
				<Col lg={3} md={4} sm={4} xs={4}>
					<img src={Images.pocaLogo2x} className={[styles.logoItems, styles.pocaLogo].join(' ')} />
				</Col>
				<Col lg={3} md={2} sm={2} xs={2}>
					<img src={Images.isLogo} className={[styles.logoItems, styles.isLogo].join(' ')} />
				</Col>
			</Row>
		</div>
	);
};

export default Topbar;
