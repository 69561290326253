import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import NotFound from '../../screens/NotFound/NotFound';
import routes from '../routes';
import Sidebar from '../Sidebar/Sidebar';
import LoginScreen from '../../screens/Login/Login';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import CreareChestionar from '../../screens/CreareChestionar/CreareChestionar';
import AtribuireDepartament from '../../screens/AtribuireDepartament/AtribuireDepartament';
import Statistici from '../../screens/Statistici/Statistici';
import Comentarii from '../../screens/Comentarii/Comentarii';
import Loader from '../../components/Loader/Loader';
import Navbar from '../Navbar/Navbar';
import Topbar from '../Topbar/Topbar';
import ForgotPassword from '../../screens/ForgotPassword/ForgotPassword';
import ChangePassword from '../../screens/ChangePassword/ChangePassword';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';

const AdminNavigation = () => {
	const [platform, setPlatform] = useState<'desktop' | 'mobile'>('desktop');
	const { path } = useRouteMatch();
	const { pathname } = useLocation();
	const history = useHistory();
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 700) setPlatform('mobile');
			else setPlatform('desktop');
		}
		window.addEventListener('resize', handleResize);
		handleResize();
	}, []);

	useEffect(()=>{
		if(pathname.endsWith('/admin')){
			history.push('/admin/');
		}
	},[pathname]);

	

	return (
		<div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
			<Topbar />
			{platform === 'mobile' && <Navbar />}
			<div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
				{platform === 'desktop' && <Sidebar />}
				<Loader />
				<ErrorHandler />
				<div style={{ display: 'flex', flex: 4 }}>
					<Switch>
						<Route exact path={`${path}/${routes.root}`}>
							<PrivateRoute>
								<CreareChestionar />
							</PrivateRoute>
						</Route>
						<Route exact path={`${path}/${routes.creareChestionar}`}>
							<PrivateRoute>
								<CreareChestionar />
							</PrivateRoute>
						</Route>
						<Route exact path={`${path}/${routes.atribuireDepartament}`}>
							<PrivateRoute>
								<AtribuireDepartament />
							</PrivateRoute>
						</Route>
						<Route exact path={`${path}/${routes.statistici}`}>
							<PrivateRoute>
								<Statistici />
							</PrivateRoute>
						</Route>
						<Route exact path={`${path}/${routes.comentarii}`}>
							<PrivateRoute>
								<Comentarii />
							</PrivateRoute>
						</Route>
						<Route exact path={`${path}/${routes.login}`}>
							<LoginScreen />
						</Route>
						<Route exact path={`${path}/${routes.forgotPassword}`}>
							<ForgotPassword />
						</Route>
						<Route path={`${path}/${routes.changePassword}`}>
							<ChangePassword />
						</Route>
						<Route path={routes.notfound}>
							<NotFound/>
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	);
};

export default AdminNavigation;
