const routes = {
	root: '',
	creareChestionar: 'creareChestionar',
	atribuireDepartament: 'atribuireDepartament',
	statistici: 'statistici',
	comentarii: 'comentarii',
	login: 'login',
	forgotPassword: 'forgotPassword',
	changePassword: 'changePassword/:userParam',
	notfound: '*',
	
};

export default routes;
