import produce from 'immer';
import { assign } from 'lodash-es';
import { ACCEPTARE_COMENTARIU, ComentariiTypes, FETCH_COMENTARII, LOADING_COMENTARII } from './actionTypes';
import { InitialState } from './payloadTypes';

const INITIAL_STATE: InitialState = {
	comentarii: [],
	error: '',
	loading: false
};

export default (state = INITIAL_STATE, action: ComentariiTypes) => {
	switch (action.type) {
		case ACCEPTARE_COMENTARIU:
		{
			const index = state.comentarii.findIndex(comm => comm.comentariu === action.payload.comment.comentariu && comm.answerSetId === action.payload.comment.answerSetId);
			const temp = [...state.comentarii];
			temp.splice(index, 1);
			return produce(state, (nexState: InitialState) =>
				assign(nexState, {
					comentarii: temp
				}));
		}
		case FETCH_COMENTARII:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					comentarii: action.payload?.comentarii
				})
			);
		case LOADING_COMENTARII:
			return produce(state, (nextState: InitialState) =>
				assign(nextState, {
					loading: action.payload
				})
			);
		default:
			return state;
	}
};
