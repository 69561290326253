import React from 'react';
import Images from '../../constants/Images';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import routes from '../routes';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import { LogOutActionSaga, LOGOUT_SAGA } from '../../store/authentication/actionTypes';
import { Redux } from '../../store/store';

interface IProps {
	loginSuccess: boolean;
	logout: () => void;
}

const Navbar = ({ loginSuccess, logout }: IProps) => {
	return (
		<div>
			<div className={styles.container}>
				<Row>
					<Col lg={12} md={12} sm={12} xs={12}>
						<img src={Images.siglaReghin} className={styles.logoReghinNavbar} />
						<a href='' className={styles.buttonMeniuNavbar} data-bs-toggle="collapse" data-bs-target="#navbar">
							<img src={Images.iconMeniuNavbar} className={styles.iconMeniuNavbar} />
						</a>
					</Col>
				</Row>
			</div >
			<Row>
				<Col lg={12} md={12} sm={12} xs={12}>
					<div className="collapse navbar-collapse" id="navbar">
						<ul className="navbar-nav mr-auto">
							{loginSuccess && (
								<>
									<li>
										<div className={styles.navItemLink}>
											<img src={Images.iconMeniuSurvey} className={styles.linkImage} />
											<Link to={routes.creareChestionar} className={styles.linkText}>
												{'Creare Chestionar'}
											</Link>
										</div>
										<li>
										</li>
										<div className={styles.navItemLink}>
											<img src={Images.iconMeniuDepartament} className={styles.linkImage} />
											<Link to={routes.atribuireDepartament} className={styles.linkText}>
												{'Atribuire departament'}
											</Link>
										</div>
									</li>
									<div className={styles.navItemLink}>
										<img src={Images.iconMeniuStatistici} className={styles.linkImage} />
										<Link to={routes.statistici} className={styles.linkText}>
											{'Statistici'}
										</Link>
									</div>
									<div className={styles.navItemLink}>
										<img src={Images.iconMeniuComentarii} className={styles.linkImage} />
										<Link to={routes.comentarii} className={styles.linkText}>
											{'Comentarii'}
										</Link>
									</div>
									<div className={styles.navItemLink}>
										<img src={Images.iconMeniuPortal} className={styles.linkImage} />
										<Link to={routes.creareChestionar} className={styles.linkText}>
											{'Inapoi la portal'}
										</Link>
									</div>
									<div className={styles.navItemLink}>
										<img src={Images.iconMeniuLogout} className={styles.linkImage} />
										<Link
											onClick={(e) => {
												e.preventDefault();
												logout();
											}}
											to={''}
											className={styles.linkText}>
											{'Delogare'}
										</Link>
									</div>
								</>
							)}
							{!loginSuccess && (
								<>
									<div className={styles.navItemLink}>
										<img src={Images.iconMeniuLogout} className={styles.linkImage} />
										<Link to={routes.login} className={styles.linkText}>
											{'Login'}
										</Link>
									</div>
									<div className={styles.navItemLink}>
										<img src={Images.iconMeniuPortal} className={styles.linkImage} />
										<Link to={routes.creareChestionar} className={styles.linkText}>
											{'Inapoi la portal'}
										</Link>
									</div>
								</>
							)}
						</ul>
					</div>
				</Col >
			</Row >
		</div>
	);
};

const mapStateToProps = (state: Redux) => {
	const { loginSuccess } = state.authentication;
	return { loginSuccess };
};

const mapDispatchToProps = (dispatch: (arg0: LogOutActionSaga) => void) => ({
	logout: () => dispatch({ type: LOGOUT_SAGA, payload: null })
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
