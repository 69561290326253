import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from '../../screens/NotFound/NotFound';
import routes from '../routes';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import HomeScreen from '../../screens/HomeScreen/HomeScreen';

const MainNavigation = () => {
	const [platform, setPlatform] = useState<'desktop' | 'mobile'>('desktop');

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 700) setPlatform('mobile');
			else setPlatform('desktop');
		};

		window.addEventListener('resize', handleResize);

		handleResize();
	}, []);

	return (
		<div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
			<Topbar />
			{platform === 'mobile' && <Navbar />}
			<div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
				{platform === 'desktop' && <Sidebar />}
				<div style={{ display: 'flex', flex: 4 }}>
					<Switch>
						<Route
							path={routes.root}
						>
							<HomeScreen />
						</Route>
						<Route path={routes.notfound}>
							<NotFound />
						</Route>
					</Switch>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default MainNavigation;
