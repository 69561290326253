import React, { useEffect } from 'react';
import { ColorRing } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { LogOutActionSaga, LOGOUT_SAGA } from '../../store/authentication/actionTypes';
import { Redux } from '../../store/store';

interface IProps {
    errorDepartamente: string,
    errorComentarii: string,
    errorAuth: string,
    errorStatistici: string,
    logout: () => void
}

const ErrorHandler = ({ errorDepartamente, errorComentarii, errorAuth, errorStatistici, logout }: IProps) => {
    useEffect(()=>{
        if(errorDepartamente == '403' || errorComentarii == '403' || errorAuth == '403' || errorStatistici == '403')
            logout();
    },[errorDepartamente, errorComentarii, errorAuth, errorStatistici]);

    return null;
};

const mapStateToProps = (state: Redux) => {
    const { error: errorDepartamente } = state.departamente;
    const { error: errorComentarii } = state.comentarii;
    const { error: errorAuth } = state.authentication;
    const { error: errorStatistici } = state.statistici;
    return { errorDepartamente, errorComentarii, errorAuth, errorStatistici };
};

const mapDispatchToProps = (dispatch: (arg0: LogOutActionSaga) => void) => ({
    logout: () => dispatch({ type: LOGOUT_SAGA, payload: null })
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
