import { ComentariuType } from './payloadTypes';

export const ACCEPTARE_COMENTARIU = 'ACCEPTARE_COMENTARIU';
export const ACCEPTARE_COMENTARIU_SAGA = 'ACCEPTARE_COMENTARIU_SAGA';
export const FETCH_COMENTARII = 'FETCH_COMENTARII';
export const FETCH_COMENTARII_SAGA = 'FETCH_COMENTARII_SAGA';
export const ERROR = 'ERROR';
export const LOADING_COMENTARII = 'LOADING_COMENTARII';

//reducer actions
export type AcceptareComentariuAction = {
	type: typeof ACCEPTARE_COMENTARIU;
	payload: {
		comment: ComentariuType,
		acceptance: boolean
	};
};

export type FetchComentariiAction = {
	type: typeof FETCH_COMENTARII;
	payload: {
		comentarii: ComentariuType[]
	};
};

export type LoadingComentariiAction = {
	type: typeof LOADING_COMENTARII;
	payload: boolean;
};

type ErrorAction = {
	type: typeof ERROR;
	payload: ErrorType;
};

//saga actions
export type AcceptareComentariuActionSaga = {
	type: typeof ACCEPTARE_COMENTARIU_SAGA;
	payload: {
		comment: ComentariuType, acceptance: boolean, userId: string
	};
};

export type FetchComentariiActionSaga = {
	type: typeof FETCH_COMENTARII_SAGA;
	payload: {
		departamentSelectat: number;
	};
};

export type ErrorType = {
	error: boolean;
};


export type ComentariiTypes =
	| AcceptareComentariuAction
	| AcceptareComentariuActionSaga
	| ErrorAction
	| FetchComentariiAction
	| FetchComentariiActionSaga
	| LoadingComentariiAction
